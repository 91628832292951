import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c('AppBar',{attrs:{"title":"Failured requests","icon":"mdi-alert-octagon"}}),_c(VRow,{staticClass:"filer-row"},[_c(VCol,{attrs:{"cols":"12","md":"2"}},[_c(VTextField,{staticClass:"search-field",attrs:{"placeholder":"Search","color":"accent","hide-details":"","outlined":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onFilter.apply(null, arguments)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c(VBtn,{attrs:{"color":"accent","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.onFilter.apply(null, arguments)}}},[_c(VIcon,[_vm._v("mdi-magnify")])],1)]},proxy:true}]),model:{value:(_vm.tableSearch),callback:function ($$v) {_vm.tableSearch=$$v},expression:"tableSearch"}})],1),_c(VCol,{attrs:{"cols":"12","md":"2"}},[_c(VSelect,{staticClass:"filter-field",attrs:{"items":_vm.types,"label":"Type","color":"accent","clearable":"","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c(VChip,{staticClass:"text-uppercase",attrs:{"outlined":"","small":""}},[_vm._v(_vm._s(item))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(item))])]}}]),model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}})],1),_c(VCol,{attrs:{"cols":"12","md":"2"}},[_c(VSelect,{staticClass:"filter-field",attrs:{"items":_vm.providers,"label":"Providers","color":"accent","clearable":"","outlined":"","hide-details":""},model:{value:(_vm.selectedProvider),callback:function ($$v) {_vm.selectedProvider=$$v},expression:"selectedProvider"}})],1),_c(VCol,{attrs:{"cols":"12","md":"2"}},[_c(VMenu,{ref:"menu",staticClass:"filter-field",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({staticClass:"filter-field",attrs:{"label":"Date from","prepend-inner-icon":"mdi-calendar","color":"accent","readonly":"","clearable":"","outlined":"","hide-details":""},model:{value:(_vm.selectedDateFrom),callback:function ($$v) {_vm.selectedDateFrom=$$v},expression:"selectedDateFrom"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.showDateFromPicker),callback:function ($$v) {_vm.showDateFromPicker=$$v},expression:"showDateFromPicker"}},[_c(VDatePicker,{attrs:{"no-title":""},on:{"input":function($event){_vm.showDateFromPicker = false}},model:{value:(_vm.selectedDateFrom),callback:function ($$v) {_vm.selectedDateFrom=$$v},expression:"selectedDateFrom"}})],1)],1),_c(VCol,{attrs:{"cols":"12","md":"2"}},[_c(VMenu,{ref:"menu",staticClass:"filter-field",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({staticClass:"filter-field",attrs:{"label":"Date to","prepend-inner-icon":"mdi-calendar","color":"accent","readonly":"","outlined":"","clearable":"","hide-details":""},model:{value:(_vm.selectedDateTo),callback:function ($$v) {_vm.selectedDateTo=$$v},expression:"selectedDateTo"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.showDateToPicker),callback:function ($$v) {_vm.showDateToPicker=$$v},expression:"showDateToPicker"}},[_c(VDatePicker,{attrs:{"no-title":""},on:{"input":function($event){_vm.showDateToPicker = false}},model:{value:(_vm.selectedDateTo),callback:function ($$v) {_vm.selectedDateTo=$$v},expression:"selectedDateTo"}})],1)],1),_c(VBtn,{attrs:{"color":"accent","text":""},on:{"click":_vm.onFilter}},[_vm._v(" SEARCH ")])],1),_c(VDataTable,{staticClass:"accent-table rounded-table",attrs:{"items":_vm.filteredFailuredRequests,"headers":[
            { text: 'Type', value: 'type' },
            { text: 'Provider', value: 'provider', sortable: false },
            { text: 'Created at', value: 'timestamp' },
            { text: 'Actions', value: 'actions', sortable: false } ],"dense":"","search":_vm.searchFilter,"custom-filter":_vm.dataTableDeepSearch,"loading":_vm.loading,"sort-by":"timestamp","item-key":"key","show-expand":""},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
        var item = ref.item;
return [_c(VChip,{staticClass:"text-uppercase",attrs:{"outlined":"","small":""}},[_vm._v(_vm._s(item.type))])]}},{key:"item.timestamp",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFullFormat")(item.timestamp))+" ")]}},{key:"item.provider",fn:function(ref){
        var item = ref.item;
return [(!!item.user.provider_name)?_c('ProviderName',{attrs:{"provider":{ id: item.user.provider_id, country_code: item.user.provider_name.split(' ')[0] }}}):_vm._e()]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"expanded-item",fn:function(ref){
        var headers = ref.headers;
        var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c(VRow,[_c(VCol,{staticClass:"d-flex flex-column",attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"pl-2 py-2 text-overline"},[_vm._v("Request")]),_c('json-viewer',{staticClass:"pa-0",attrs:{"value":item.request,"copyable":{copyText: 'Copy', copiedText: 'Copied!', timeout: 2000},"expand-depth":1}})],1),_c(VDivider,{staticClass:"my-3",attrs:{"vertical":""}}),_c(VCol,{staticClass:"d-flex flex-column",attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"pl-2 py-2 text-overline"},[_vm._v("Response")]),_c('json-viewer',{staticClass:"pa-0",attrs:{"value":item.response,"copyable":{copyText: 'Copy', copiedText: 'Copied!', timeout: 2000},"expand-depth":1}})],1)],1)],1)]}}])}),_c(VDialog,{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v("Are you sure you want to delete this item?")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"mt-5 mr-2 v-btn v-btn--outlined v-btn--router theme--light v-size--default accent--text",attrs:{"text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c(VBtn,{staticClass:"mt-5 mr-2 v-btn v-btn--outlined v-btn--router theme--light v-size--default accent--text",attrs:{"text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c(VSpacer)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }