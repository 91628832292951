<template>
    <v-container fluid>

        <v-tabs vertical>
            <v-tab v-for="job in jobsToShow" 
                :key="job.taskName"
                class="justify-start"
                active-class="active-tab"
            >
                <v-icon left>mdi-file-tree</v-icon>{{job.title}}
            </v-tab>

            <v-tab-item v-for="job in jobsToShow" 
                :key="job.taskName"
            >
                <v-card flat>
                    <v-card-text>
                        <div v-html="job.description" class="mb-6"></div>

                        <v-row>
                            <v-text-field
                                class="px-3 pb-6"
                                v-model="tableSearch[job.taskName]"
                                clearable
                                hide-details
                                outlined
                                dense
                                color="accent"
                                append-icon="mdi-magnify"
                                placeholder="Search"
                                >
                            </v-text-field>
                            <v-btn
                                class="mr-3"
                                elevation="0"
                                color="accent"
                                min-height="40"
                                outlined
                                :loading="runJobLoading"
                                @click="runJob(job.taskName)"
                                >
                                Run job
                            </v-btn>
                        </v-row>

                        <v-data-table
                            class="jobs-table"
                            v-if="cronjobs"
                            :headers="[
                                {text: 'Success', value: 'success', align: 'center' },
                                {text: 'Job', value: 'job', align: 'center'},
                                {text: 'Provider/Connection', value: 'provider', align: 'center'},
                                {text: 'Error response', value: 'error_response', align: 'center'},
                                {text: 'Start time', value: 'start_at', align: 'center'},
                                {text: 'End time', value: 'end_at', align: 'center'},
                            ]"
                            :items="cronjobs.filter(cronjob => cronjob.task_name == job.taskName)"
                            :custom-filter="dataTableDeepSearch"
                            :search="tableSearch[job.taskName]"               
                            sort-by="created_at"
                            sort-desc
                        >
                            <template v-slot:item.success="{ item }">
                                <v-icon v-if="item.success" color="success">mdi-check-circle</v-icon>
                                <v-icon v-else color="error">mdi-close-circle</v-icon>
                            </template>
                            <template v-slot:item.job="{ item }">
                                {{item.task_name}} #{{item.id}}
                            </template>
                            <template v-slot:item.provider="{ item }">
                                <ProviderName
                                    v-if="item.user_id"
                                    :provider="{ applicable_user: item.user_id ,username: item.user_company_name }"
                                    extra="applicable_user"
                                />
                                <ProviderName
                                    v-if="item.provider_id"
                                    :provider="{ id: item.provider_id, country_code: item.provider_country_code, party_id: item.provider_party_id }"
                                />
                            </template>
                            <template v-slot:item.error_response="{ item }">
                                <json-viewer v-if="item.error_response"
                                    class="pa-2 text-left"
                                    :value="item.error_response"
                                    :expand-depth="0"
                                    >
                                </json-viewer>
                            </template>
                            <template v-slot:item.start_at="{ item }">
                                {{ item.start_at | dateFullFormat }}
                            </template>
                            <template v-slot:item.end_at="{ item }">
                                {{ item.end_at | dateFullFormat }}
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs> 
    </v-container>
</template>

<script>
import ProviderName from "@/components/provider/ProviderName"

export default {
    name: "provider-cronjobs",
    components: {
        ProviderName
    },
    props:["provider_id", "roles"],
    data() {
        return {
          provider: null,
          apiUrlGet: '',
          apiUrlPost: '',
          tableSearch: {},
          cronjobs: [],
          jobs: [
            {
              taskName: 'fetch_structure',
              role: [],
              title: 'Fetch structure',
              description: `<p>Update DTM Structure to OCPI. Launches <strong>daily</strong> at <strong>00:00</strong>.</p>`
            },
            {
              taskName: 'check_connection',
              role: ['cpo', 'emsp', 'nsp'],
              title: 'Check connection',
              description: `<p>GET /versions to check that connection is online. If it does not receive a response, it marks connection as DISCONNECTED. Launches <strong>every hour</strong>.</p>`
            },
            {
              taskName: 'check_core_locations',
              role: [],
              title: 'Check Core locations',
              description: `<p>Check that Core and OCPI have same number of locations and connectors. If there is a data inconsistency it launches an alert to Sentry. Launches <strong>daily</strong> at <strong>00:00</strong>.</p>`
            },
            {
              taskName: 'fetch_tokens',
              role: ['emsp'],
              title: 'Fetch tokens',
              description: `<p>Update tokens of EMSP providers with flag 'flag_fetch_tokens_auto'. Launches <strong>daily</strong> at <strong>00:00</strong>.</p>
                <p>Tokens are updated by <a href="https://github.com/ocpi/ocpi/blob/2.1.1-d2/mod_tokens.md#221-get-method">OCPI GET Method</a> with pages of 20, dates from the last job successes and launch date.</p>`
            },
            {
              taskName: 'fetch_locations',
              role: ['cpo'],
              title: 'Fetch Locations',
              description: `<p>Update locations of CPO providers with flag 'flag_fetch_locations_auto'. Launches <strong>daily</strong> at <strong>00:00</strong>.</p>
                <p>Locations are updated by <a href="https://github.com/ocpi/ocpi/blob/2.1.1-d2/mod_locations.md#211-get-method">OCPI GET Method</a> with pages of 100, dates from the last job successes and launch date.</p>`
            },
            {
              taskName: 'fetch_cdrs',
              role: ['cpo'],
              title: 'Fetch CDRs',
              description: `<p>Update CDRs of CPO providers with flag 'flag_fetch_cdrs_auto'. Launches <strong>daily</strong> at <strong>00:00</strong>.</p>
                <p>CDRs are updated by <a href="https://github.com/ocpi/ocpi/blob/2.1.1-d2/mod_cdrs.md#211-get-method">OCPI GET Method</a> with pages of 100, dates from the last job successes and launch date.</p>`
            },
            {
              taskName: 'fetch_tariffs',
              role: ['cpo'],
              title: 'Fetch tariffs',
              description: `<p>Update tariffs of CPO providers with flag 'flag_fetch_tariffs_auto'. Launches <strong>daily</strong> at <strong>00:00</strong>.</p>
                <p>Tariffs are updated by <a href="https://github.com/ocpi/ocpi/blob/2.1.1-d2/mod_tariffs.md#211-get-method">OCPI GET Method</a> with pages of 100, dates from the last job successes and launch date.</p>`
            },
            {
              taskName: 'put_locations',
              role: ['emsp', 'nsp'],
              title: 'Put Locations',
              description: `<p>Update locations of Wenea as provider for providers flag 'flag_put_locations_auto'. Launches <strong>daily</strong> at <strong>00:00</strong>.</p>
                <p>Locations are updated by <a href="https://github.com/ocpi/ocpi/blob/2.1.1-d2/mod_locations.md#222-put-method">OCPI PUT Method</a> dates from the last job successes and launch date.</p>`
            },
          ], 
          runJobLoading: false

        }
    },
    computed:{
        jobsToShow() {
            if (!!this.roles && this.roles.length > 0) {
                return this.jobs.filter(job => 
                    this.roles.map(role => role.toLowerCase())
                        .find( role => job.role.find(j => j.toLowerCase() == role) ) 
                )
            }
            return this.jobs
        }
    },
    mounted() {
        this.getCronJobs();
    },
    methods: {
      getCronJobs(){
            if(this.provider_id){
                this.apiUrlGet = `provider/${this.provider_id}/cron-jobs`;
            }else{
                this.apiUrlGet = `cron-jobs`;
            }
        this.$api.get(this.apiUrlGet)
          .then(resp => this.cronjobs = resp.data )
          .catch(this.showError)
      },
      runJob(taskName){
            if(this.provider_id){
                this.apiUrlPost = `cron-jobs/${taskName}/run?provider_id=${this.provider_id}`
            }else{
                this.apiUrlPost = `cron-jobs/${taskName}/run`
            }
        this.runJobLoading = true

        this.$api.post(this.apiUrlPost)
          .then(() => this.showSuccess('Task has been executed. Refresh table to see result.'))
          .catch(this.showError)
          .then(() => this.runJobLoading = false)
      }
    }
}
</script>

<style scoped>
    .jobs-table {
        border: thin solid rgba(0, 0, 0, 0.12);
    }
    .a-transparent-background:before {
        opacity: 0;
    }
    >>> .jv-code{
        padding: 0!important;
    }
    >>> .jv-container.jv-light {
        background: transparent !important;
    }
    .active-tab,
    >>>  .v-tabs-slider {
        color: var(--v-accent-lighten1) !important;
    }
</style>