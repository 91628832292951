<template>
  <div>
    <!-- Table filters -->
    <v-row class="filer-row">

      <v-spacer v-if="toolbarRight" />

      <v-col cols="12" md="2">
          <v-text-field 
            class="search-field"
            v-model="selectedSearch"  
            placeholder="Search"
            color="accent"
            autofocus
            outlined 
            hide-details
            @keyup.enter="onFilter"
          >
            <template v-slot:append>
              <v-btn
                  color="accent" 
                  text
                  @click.stop="onFilter"
              >
                  <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
          </v-text-field>
      </v-col>
      
      <v-col cols="12" md="2">
          <v-menu
              ref="menu"
              class="filter-field"
              v-model="showDatePicker"
              :close-on-content-click="false"
              transition="scale-transition"
              min-width="auto"
              color="accent"
              hide-details
              offset-y
          >
              <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    v-on="on"
                    class="filter-field"
                    v-model="selectedDate"
                    label="Date"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    outlined
                    clearable
                    hide-details
                  ></v-text-field>
              </template>
              <v-date-picker
                  v-model="selectedDate"
                  @input="showDatePicker = false"
                  no-title
              ></v-date-picker>
          </v-menu>
      </v-col>

      <v-btn 
          @click="onFilter"
          color="accent"
          text
      >
        SEARCH
      </v-btn>
    </v-row>

    <!-- Data table -->
    <v-data-table
      class="accent-table rounded-table"
      :headers="[
          {text: 'Success', value: 'success', align: 'center' },
          {text: 'Id.', value: 'id', align: 'center'},
          {text: 'Backend uuid', value: 'backend_uuid', align: 'center'},
          {text: 'CDR Id.', value: 'cdr_id', align: 'center'},
          {text: 'Applied tariff', value: 'applied_tariff', align: 'center'},
          {text: 'Error response', value: 'error_response', align: 'center'},
          {text: 'Created at', value: 'created_at', align: 'center'},
          {text: 'Updated at', value: 'updated_at', align: 'center'},
          {text: 'Actions', value: 'actions', align: 'center'}
      ]"
      :items="filteredProcesses"
      sort-by="created_at"
      :search="tableSearch"
      sort-desc  
    >
        <template v-slot:item.success="{ item }">
            <v-btn v-if="item.success == null" loading text></v-btn>
            <v-icon v-else-if="item.success" color="success">mdi-check-circle</v-icon>
            <v-icon v-else color="error">mdi-close-circle</v-icon>
        </template>
        <template v-slot:item.backend_uuid="{ item }">
            <v-btn v-if="item.session_id"
              text  
              :to="`/session/${item.session_id}`" 
            >
              {{item.backend_uuid}}
            </v-btn>
            <span v-else>{{item.backend_uuid}}</span>
        </template>
        <template v-slot:item.applied_tariff="{ item }">
            <v-btn v-if="item.tariff_id"
              text
              :to="`/tariffs/${item.tariff_id}`" 
            >
              <v-icon left>mdi-tag-text-outline</v-icon> {{item.tariff_id}}
            </v-btn>
        </template>
        <template v-slot:item.error_response="{ item }">
            <json-viewer v-if="item.error_response"
                class="pa-2 text-left"
                :value="item.error_response"
                :expand-depth="0"
                >
            </json-viewer>
        </template>
        <template v-slot:item.created_at="{ item }">
            {{ item.created_at | dateFullFormat }}
        </template>
        <template v-slot:item.updated_at="{ item }">
            {{ item.updated_at | dateFullFormat }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn v-if="!item.success"
            color="accent"  
            depressed
            @click="retryProcesses(item.id)">
            <v-icon left>mdi-play</v-icon>Retry
          </v-btn>
      </template>
    </v-data-table>

  </div>
</template>

<script>

export default {
  name: "cdr-processes",
  props: {
      providerId: {
        type: Number,
      },
      toolbarRight: {
        type: Boolean,
        default: false
      },
    },
    data() {
        return {
          processes: [],
          filteredProcesses: [],
          selectedDate: null,
          showDatePicker: false,
          selectedSearch: null,
          tableSearch: null,
          loading: false,
        }
    },
    watch: {
        //Necessary to update the filters when the user uses the browser back/forward button also to update filters when the user refresh the page
        '$route.query':{ 
            handler(params) {
                this.routeFilters(params)
                this.onFilter()
            },
            immediate: true
        },
    },
    methods: {
      async getProcesses(){
        this.loading = true
        //Depending if the provider is looking into EMSP or CPO view 
        const getURL = `cdr_calculation_processes${this.providerId? `?provider_id=${this.providerId}` : ''}`
        await this.$api.get(getURL) 
          .then(resp => {this.processes = resp.data
          })
          .catch(this.showError)
          .finally(() => this.loading = false)
      },
      retryProcesses(id){
        this.$api.post(`cdr_calculation_processes/${id}/retry`)
          .then(() => this.showSuccess('Process has been executed. Refresh table to see result.'))
          .catch(this.showError)
      },
      filterDate(date){
        this.filteredProcesses = this.processes
          .filter(process => (process.created_at && process.created_at.includes(date)) 
            || (process.updated_at && process.updated_at.includes(date)))
      },
      async onFilter(){
        if(this.loading) return
        this.tableSearch = this.selectedSearch
        this.urlPush('search', this.selectedSearch)
        this.urlPush('date', this.selectedDate)

        await this.getProcesses()

        if(!this.selectedDate) { 
                this.selectedDate = null 
                this.filteredProcesses = this.processes
        }else{
            this.filterDate(this.selectedDate)
        }
      }
    },
};
</script>

<style scoped>
  >>> .jv-code{
    padding: 0!important;
  }
  >>> .jv-container.jv-light {
    background: transparent !important;
  }
</style>