import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c('AppBar',{attrs:{"title":"Export Locations to Core","icon":"mdi-database-export"}}),_c('div',{staticClass:"d-flex my-4"},[_c(VIcon,{attrs:{"color":"accent"}},[_vm._v("mdi-database-export-outline")]),_c('h3',{staticClass:"ml-2"},[_vm._v("Select Locations to export to Core")])],1),_c(VTextarea,{staticClass:"mb-4 text-overline",staticStyle:{"font-size":"1rem!important"},attrs:{"auto-grow":"","outlined":"","hide-details":"","background-color":"grey lighten-4"},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_vm._v(" SELECT id FROM ( ")]},proxy:true},{key:"append",fn:function(){return [_vm._v(" ) AS a ")]},proxy:true}]),model:{value:(_vm.sqlquery),callback:function ($$v) {_vm.sqlquery=$$v},expression:"sqlquery"}}),(!_vm.loading)?_c(VRow,{staticClass:"ma-0",attrs:{"align":"center"}},[_c(VBtn,{attrs:{"color":"accent"},on:{"click":_vm.exportLocations}},[_vm._v("Export")]),(_vm.results)?_c(VChip,{staticClass:"ml-2",attrs:{"outlined":"","color":"accent"}},[_vm._v(_vm._s(_vm.exported)+" Locations exported to Core")]):_vm._e()],1):_c(VRow,{staticClass:"ma-0",attrs:{"align":"center"}},[_c(VBtn,{attrs:{"color":"accent"},on:{"click":_vm.forceStop}},[_vm._v("Force Stop")]),_c(VChip,{staticClass:"ml-2",attrs:{"outlined":"","color":"accent"}},[_c(VProgressCircular,{staticClass:"mr-2",attrs:{"size":16,"width":"2","indeterminate":"","color":"accent"}}),(_vm.stopping)?[_vm._v(" Stopping ")]:[_vm._v(" Exported "+_vm._s(_vm.exported)+" of "+_vm._s(_vm.total ? _vm.total : '?')+" ")]],2)],1),(_vm.results)?_c(VCard,{staticClass:"mt-4",attrs:{"outlined":""}},[_c(VDataTable,{attrs:{"items":_vm.results,"headers":[
                { text: 'Location id', value: 'loc_id' },
                { text: 'Chargepoint id', value: 'chargepoint_id' },
                { text: 'Success?', value: 'success' },
                { text: 'Message', value: 'message' } ],"dense":"","group-by":"location_id","sort-by":"chargepoint_id","loading":_vm.loading},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
            var group = ref.group;
            var items = ref.items;
            var headers = ref.headers;
            var toggle = ref.toggle;
            var isOpen = ref.isOpen;
return [_c('td',[_c(VRow,{staticClass:"ma-0 pa-0",attrs:{"align":"center"}},[_c(VBtn,{ref:group,attrs:{"icon":""},on:{"click":toggle}},[(isOpen)?_c(VIcon,[_vm._v("mdi-minus")]):_c(VIcon,[_vm._v("mdi-plus")])],1),_c('div',{staticStyle:{"white-space":"nowrap"}},[_c(VBtn,{staticClass:"px-1",staticStyle:{"min-width":"16px"},attrs:{"plain":"","small":"","to":("/location/" + group)}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-arrow-top-right")])],1),_vm._v(" "+_vm._s(group)+" ")],1)],1)],1),_c('td',[_vm._v(" "+_vm._s(items.filter(function (i) { return !!i.chargepoint_id; }).map(function (i) { return i.chargepoint_id; }))+" ")]),_c('td',[_c(VIcon,{attrs:{"color":items.every(function (i) { return i.success; }) ? 'success' : 'error'}},[_vm._v(" "+_vm._s(items.every(function (i) { return i.success; }) ? 'mdi-check-circle' : 'mdi-close-circle')+" ")])],1),_c('td',{attrs:{"colspan":headers.length - 3}})]}},{key:"item.loc_id",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_c(VBtn,{staticClass:"px-1",staticStyle:{"min-width":"16px"},attrs:{"plain":"","small":"","to":("/location/" + (item.location_id))}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-arrow-top-right")])],1),_vm._v(" "+_vm._s(item.location_id)+" ")],1)]}},{key:"item.chargepoint_id",fn:function(ref){
            var item = ref.item;
return [(item.chargepoint_id)?_c('div',{staticStyle:{"white-space":"nowrap"}},[_c(VBtn,{staticClass:"px-1",staticStyle:{"min-width":"16px"},attrs:{"plain":"","small":"","to":("/chargepoint/" + (item.chargepoint_id))}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-arrow-top-right")])],1),_vm._v(" "+_vm._s(item.chargepoint_id)+" ")],1):_vm._e()]}},{key:"item.success",fn:function(ref){
            var item = ref.item;
return [_c(VIcon,{attrs:{"color":item.success ? 'success' : 'error'}},[_vm._v(" "+_vm._s(item.success ? 'mdi-check-circle' : 'mdi-close-circle')+" ")])]}}],null,false,2005144386)},[_c('template',{slot:"progress"},[_c(VProgressLinear,{attrs:{"color":"accent"},model:{value:(_vm.progress),callback:function ($$v) {_vm.progress=$$v},expression:"progress"}})],1)],2)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }