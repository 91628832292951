import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c('AppBar',{attrs:{"title":"Charge requests","icon":"mdi-application-import"}}),_c(VRow,{staticClass:"filer-row"},[_c(VCol,{attrs:{"cols":"12","md":"2"}},[_c(VTextField,{staticClass:"search-field",attrs:{"placeholder":"Search","append-icon":"mdi-magnify","color":"accent","hide-details":"","outlined":"","autofocus":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onFilter.apply(null, arguments)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c(VBtn,{attrs:{"color":"accent","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.onFilter.apply(null, arguments)}}},[_c(VIcon,[_vm._v("mdi-magnify")])],1)]},proxy:true}]),model:{value:(_vm.selectedSearch),callback:function ($$v) {_vm.selectedSearch=$$v},expression:"selectedSearch"}})],1),_c(VCol,{attrs:{"cols":"12","md":"2"}},[_c(VAutocomplete,{staticClass:"filter-field",attrs:{"items":_vm.providers,"label":"Provider","color":"accent","outlined":"","clearable":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('ProviderSelectorTemplate',{attrs:{"provider":data.item}})]}}]),model:{value:(_vm.selectedProvider),callback:function ($$v) {_vm.selectedProvider=$$v},expression:"selectedProvider"}})],1),_c(VCol,{attrs:{"cols":"12","md":"2"}},[_c(VMenu,{ref:"menu",staticClass:"filter-field",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({staticClass:"filter-field",attrs:{"label":"Date from","prepend-inner-icon":"mdi-calendar","color":"accent","outlined":"","readonly":"","hide-details":""},model:{value:(_vm.selectedDateFrom),callback:function ($$v) {_vm.selectedDateFrom=$$v},expression:"selectedDateFrom"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.showDatePicker),callback:function ($$v) {_vm.showDatePicker=$$v},expression:"showDatePicker"}},[_c(VDatePicker,{attrs:{"no-title":""},on:{"input":function($event){_vm.showDatePicker = false}},model:{value:(_vm.selectedDateFrom),callback:function ($$v) {_vm.selectedDateFrom=$$v},expression:"selectedDateFrom"}})],1)],1),_c(VBtn,{attrs:{"loading":_vm.loading,"color":"accent","text":""},on:{"click":_vm.onFilter}},[_vm._v(" SEARCH ")])],1),_c(VDataTable,{staticClass:"accent-table rounded-table",attrs:{"items":_vm.chargeRequests,"headers":[
          { text: 'Provider', value: 'provider' },
          { text: 'Type', value: 'type' },
          { text: 'Chargepoint', value: 'chargepoint'},
          { text: 'Command id', value: 'command_id' },
          { text: 'Step', value: 'step' },
          { text: 'Success', value: 'success' },
          { text: 'Created at', value: 'timestamp' } ],"dense":"","search":_vm.tableSearch,"custom-filter":_vm.dataTableDeepSearch,"loading":_vm.loading,"item-key":"id","sort-by":"timestamp","sort-desc":"","show-expand":""},scopedSlots:_vm._u([{key:"item.provider",fn:function(ref){
      var item = ref.item;
return [(item.user.provider_name)?_c('ProviderName',{attrs:{"provider":{ id: item.user.provider_id, country_code: item.user.provider_name.split('-')[0], party_id: item.user.provider_name.split('-')[1].split(' as ')[0]  },"role":"emsp"}}):_vm._e()]}},{key:"item.type",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"text-overline"},[_vm._v(_vm._s(item.type))]),(item.command_type)?_c('span',{staticClass:"text-overline"},[_vm._v(" "+_vm._s(item.command_type.split('_')[0]))]):_vm._e()]}},{key:"item.chargepoint",fn:function(ref){
      var item = ref.item;
return [(item.chargepoint && item.chargepoint.name)?_c('ChargepointName',{attrs:{"chargepoint":{ id: item.chargepoint.id, name: item.chargepoint.name },"type":"table"}}):_vm._e()]}},{key:"item.success",fn:function(ref){
      var item = ref.item;
return [(item.success !== undefined && item.success !== null)?[(item.success)?_c(VIcon,{attrs:{"color":"success"}},[_vm._v("mdi-check-circle")]):_c(VIcon,{attrs:{"color":"error"}},[_vm._v("mdi-close-circle")])]:_c(VIcon,{attrs:{"color":"warning"}},[_vm._v("mdi-help-circle")])]}},{key:"item.timestamp",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFullFormat")(item.timestamp))+" ")]}},{key:"expanded-item",fn:function(ref){
      var headers = ref.headers;
      var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[(item.type == 'rta')?[_c(VCol,{staticClass:"d-flex flex-column",attrs:{"cols":"12","md":"12"}},[_c('span',{staticClass:"pl-2 py-2 text-overline"},[_vm._v("Request")]),_c('json-viewer',{staticClass:"pa-0",attrs:{"value":(function (ref){
            var id = ref.id;
            var rest = objectWithoutProperties( ref, ["id"] );
            var args = rest;

            return args;
      })(item),"copyable":{copyText: 'Copy', copiedText: 'Copied!', timeout: 2000},"expand-depth":1}})],1)]:_vm._e(),(item.type == 'command')?[_c(VRow,{staticClass:"pb-3"},[_c(VCol,{staticClass:"d-flex flex-column",attrs:{"cols":"5","md":"5"}},[_c('span',{staticClass:"pl-2 py-3 text-overline"},[_vm._v("Sync response")]),_c('json-viewer',{staticClass:"pa-0",attrs:{"value":item.syncCommand,"copyable":{copyText: 'Copy', copiedText: 'Copied!', timeout: 2000},"expand-depth":1}})],1),_c(VCol,{staticClass:"d-flex flex-column",attrs:{"cols":"5","md":"5"}},[_c(VRow,{staticClass:"pt-3"},[_c(VDivider,{attrs:{"vertical":""}}),_c(VCol,{staticClass:"pt-0 d-flex flex-column"},[_c('span',{staticClass:"pl-2 py-2 text-overline"},[_vm._v("Async response")]),(item.asyncCommand)?_c('json-viewer',{staticClass:"pa-0",attrs:{"value":item.asyncCommand,"copyable":{copyText: 'Copy', copiedText: 'Copied!', timeout: 2000},"expand-depth":1}}):_c('span',{staticClass:"pl-2 json-text-orange"},[_vm._v("null")])],1)],1)],1)],1)]:_vm._e()],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }