<template>
    <v-container fluid>
    <div>

        <AppBar :breadcrumbs="[{ text: 'Settings', icon: 'mdi-cog' }]"/>
        <div :loading="loading"></div>
        <v-progress-linear v-if="loading" indeterminate></v-progress-linear>

        <v-card outlined class="mb-4">
            <v-list-item-title class="ml-4 mt-2">
                <h2 class="d-flex align-content-center">
                    <v-icon left color="accent">mdi-text-box-outline</v-icon>
                    Log level
                </h2>
            </v-list-item-title>
            <v-card-text>
                <v-row class="filer-row">
                    <v-col>
                        <v-select
                            class="filter-field"
                            v-model="settings.loglevel"
                            :items="loglevels"
                            label="Worker log level"
                            @change="save('loglevel')"
                            color="accent"
                            outlined
                            hide-details
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-select
                            class="filter-field"
                            v-model="settings.vendorLoglevel"
                            :items="loglevels"
                            label="Vendor log level"
                            @change="save('vendorLoglevel')"
                            color="accent"
                            outlined
                            hide-details
                        ></v-select>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card outlined class="mb-4">
            <v-list-item-title class="ml-4 mt-2">
                <h2 class="d-flex align-content-center">
                    <v-icon left color="accent">mdi-file-tree-outline</v-icon>
                    Update CP structure
                </h2>
            </v-list-item-title>
            <v-card-text>
                <h4 class="ml-2 mb-2">
                    Gets
                    <v-chip small> LocationGroups </v-chip>,
                    <v-chip small> Locations </v-chip>,
                    <v-chip small> ChargePoints </v-chip>,
                    <v-chip small> Evses </v-chip> and
                    <v-chip small> Connectors </v-chip>
                    from DTM and update ones saved in OCPI
                </h4>
                <v-btn @click="updateOcpiDataFromDtm" :loading="updateDataLoading" text outlined color="accent">
                    <v-icon left>mdi-update</v-icon>
                    Run Update Process
                    <template v-slot:loader>
                        <v-icon left>mdi-loading mdi-spin</v-icon>
                        <span>Updating ...</span>
                    </template>
                </v-btn>
                <span class="ml-4">(This process may take a few minutes)</span>
                <template v-if="updateDataResult">
                    <v-divider class="my-4" />
                    <div>{{ updateDataResult }}</div>
                </template>
            </v-card-text>
        </v-card>

        <v-card outlined class="mb-4">
            <v-list-item-title class="ml-4 mt-2">
                <h2 class="d-flex align-content-center">
                    <v-icon left color="accent">mdi-import</v-icon>
                    Evse status sync: DTM <v-icon left color="accent" right>mdi-arrow-right-bold</v-icon> OCPI
                </h2>
            </v-list-item-title>
            <v-card-text>
                <h4 class="ml-2 mb-2">  
                    Gets <v-chip small>Evses</v-chip> from DTM and update its status
                </h4>
                <v-btn @click="updateOcpiEvsesStatusFromDtm" :loading="updateEvsesStatusLoading" text outlined color="accent">
                    <v-icon left>mdi-update</v-icon>
                    Run Update Process
                    <template v-slot:loader>
                        <v-icon left>mdi-loading mdi-spin</v-icon>
                        <span>Updating ...</span>
                    </template>
                </v-btn>
                <span class="ml-4" v-if="updateEvsesSatatusResult">{{ updateEvsesSatatusResult }}</span>
            </v-card-text>
        </v-card>

        <v-card outlined class="mb-4">
            <v-list-item-title class="ml-4 mt-2">
                <h2 class="d-flex align-content-center">
                    <v-icon left color="accent">mdi-account-arrow-left</v-icon>
                    Evse status sync: Provider <v-icon left color="accent" right>mdi-arrow-right-bold</v-icon> OCPI
                </h2>
            </v-list-item-title>

            <v-card-text>
                <h4 class="ml-2 mb-2">  
                    Obtains list of locations of a provider to update <v-chip small>Evse status</v-chip> in OCPI.
                </h4>
                <EvStatusFromProviderSyncTool class="mt-4 pa-2"/>
            </v-card-text>
        </v-card>

        <v-card outlined>
            <v-list-item-title class="ml-4 mt-2">
                <h2 class="d-flex align-content-center">
                    <v-icon left color="accent">mdi-export</v-icon>
                    Evse status sync: OCPI <v-icon left color="accent" right>mdi-arrow-right-bold</v-icon> Core
                </h2>
            </v-list-item-title>
            <v-card-text>
                <h4 class="ml-2 mb-2">  
                    Synchronizes <v-chip small>Evse status</v-chip> of external providers stored in OCPI to Core.
                </h4>
                <EvStatusToCoreSyncTool class="mt-4 pa-2"/>
            </v-card-text>
        </v-card>
        
    </div>
    </v-container>
</template>

<script>
import AppBar from "@/components/AppBar"
import EvStatusFromProviderSyncTool from "@/components/settings/EvStatusFromProviderSyncTool"
import EvStatusToCoreSyncTool from "@/components/settings/EvStatusToCoreSyncTool"

export default {
    name: "providers",
    components: { AppBar, EvStatusFromProviderSyncTool, EvStatusToCoreSyncTool },
    data() {
        return {
            loglevels: ['debug', 'info', 'warning', 'error', 'critical'],
            loading: false,

            settings: {},

            updateDataLoading: false,
            updateDataResult: null,

            updateEvsesStatusLoading: false,
            updateEvsesSatatusResult: null,

        }
    },
    async mounted() {

        this.loading = true
        this.$api.get(`/config`)
            .then(resp => { this.settings = resp.data })
            .catch(this.showError)
            .then(_ => { this.loading = false })

    },
    methods: {
        save(key) {
            this.$api.get(`/config/set/${key}/${this.settings[key]}`)
                .then(_ => this.showSuccess())
                .catch(this.showError)

        },
        updateOcpiDataFromDtm(){

            this.updateDataResult = null
            this.updateDataLoading = true
            this.$api.get('/wenea/update-data-from-dtm')
                .then(resp => {
                    this.updateDataResult = resp.data
                    this.showSuccess('Data updated successfully')
                })
                .catch(this.showError)
                .then(_ => { this.updateDataLoading = false })

        },
        updateOcpiEvsesStatusFromDtm(){

            this.updateEvsesSatatusResult = null
            this.updateEvsesStatusLoading = true
            this.$api.get('/wenea/update-evses-status-from-dtm')
                .then(resp => {
                    this.updateEvsesSatatusResult = `Updated ${resp.data} evses.`
                    this.showSuccess('Data updated successfully')
                })
                .catch(this.showError)
                .then(_ => { this.updateEvsesStatusLoading = false })

        },
    }
}
</script>