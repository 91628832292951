<template>
  <div>
    <v-row class="filer-row">
      <v-spacer></v-spacer>
      <v-col cols="12" md="2">
        <v-menu
          ref="menu"
          class="filter-field"
          v-model="showDateFromPicker"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
          hide-details
        >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  v-on="on"
                  class="filter-field"
                  v-model="selectedDateFrom"
                  label="Date from"
                  prepend-inner-icon="mdi-calendar"
                  color="accent"
                  readonly
                  clearable
                  outlined
                  hide-details
                ></v-text-field>
            </template>
            <v-date-picker
                v-model="selectedDateFrom"
                @input="showDateFromPicker = false"
                no-title
            ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" md="2">
        <v-menu
            ref="menu"
            class="filter-field"
            v-model="showDateToPicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
            hide-details
        >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  v-on="on"
                  class="filter-field"
                  v-model="selectedDateTo"
                  label="Date to"
                  prepend-inner-icon="mdi-calendar"
                  color="accent"
                  readonly
                  clearable
                  outlined
                  hide-details
                ></v-text-field>
            </template>
            <v-date-picker
                v-model="selectedDateTo"
                @input="showDateToPicker = false"
                no-title
            ></v-date-picker>
        </v-menu>
      </v-col>

      <v-btn 
        :loading="tableLoading"
        @click="onFilter"
        color="accent"
        text
      >
        SEARCH
      </v-btn>
    </v-row>

    <v-divider />

    <v-data-table
        :headers="[
            { value: 'action', sortable:false, cellClass: 'td-linkto' },
            { text: 'Id.', value: 'id' },
            { text: 'Start Date', value: 'start_date_time' },
            { text: 'End Date', value: 'end_date_time' },
            { text: 'CPO / EMSP', value: 'sender_receiver' },
            { text: 'UID', value: 'ocpi_id' },
            { text: 'Location', value: 'location', width:'200px' },
            { text: 'Charge point', value: 'chargepoint', width:'200px' },
            { text: 'Evse', value: 'evse.uid' },
            { text: 'Energy (kWh)', value: 'kwh' },
            { text: 'Id. DTM', value: 'dtm_session_id' },
            { text: 'Last updated', value: 'last_updated' },
        ]"
        :items="sessions"
        disable-sort
        class="accent-table rounded-table"
        :items-per-page="tableOptions.itemsPerPage"
        :server-items-length="pagination.serverItemsLength"
        :footer-props="{ 'items-per-page-options': pagination.itemsPerPageOptions }"
        @update:options="this.updateTableOptions"  
    >
        <template v-slot:item.action="{ item }">
            <v-btn plain
                small
                :to="`/session/${item.id}`" 
                >
                <v-icon small>mdi-arrow-top-right</v-icon>
            </v-btn>
        </template>
        <template v-slot:item.sender_receiver="{ item }">
            <!-- Sender -->
            <v-chip v-if="item.sender_id === 1" color="secondary" small class="text-capitalize">{{ item.sender_name }}</v-chip>
            <strong v-else>
              <ProviderName
                :provider="item.senderProvider"
                role="cpo"
              />
            </strong>
            <!-- Arrow -->
            <v-icon color="secondary">mdi-arrow-right</v-icon>
            <!-- Receiver -->
            <v-chip v-if="item.receiver_id === 1" color="accent" small class="text-capitalize">{{ item.receiver_name }}</v-chip>
            <strong v-else>
              <ProviderName
                :provider="item.receiverProvider"
                role="emsp"
              />
            </strong>
        </template>
        <template v-slot:item.location="{ item }">
            <LocationName
                :location="{ id: item.location_id, name: item.location_name }"
                type="table"
            />
        </template>
        <template v-slot:item.chargepoint="{ item }">
            <ChargepointName
                :chargepoint="{ id: item.chargepoint_id, name: item.chargepoint_name }"
                type="table"
            />
        </template>
        <template v-slot:item.start_date_time="{ item }">
            {{ item.start_date_time | dateFullFormat }}
        </template>
        <template v-slot:item.end_date_time="{ item }">
            {{ item.end_date_time | dateFullFormat }}
        </template>
        <template v-slot:item.last_updated="{ item }">
            {{ item.last_updated | dateFullFormat }}
        </template>
    </v-data-table>

    <ImportSessionsFromProvider :provider-id="providerId" v-if="hasRole('CPO')" @data-imported="loadSessions()" class="mt-2"></ImportSessionsFromProvider>
  </div>
</template>
<script>
import ImportSessionsFromProvider from "@/components/provider/ImportSessionsFromProvider";
import ProviderName from "@/components/provider/ProviderName"
import LocationName from "@/components/location/LocationName"
import ChargepointName from "@/components/chargepoint/ChargepointName"

export default {
  name: "provider-sessions",
  props: ["providerId","providerRole"],
  components: {
    ImportSessionsFromProvider,
    ProviderName,
    LocationName,
    ChargepointName
  },
  data() {
    return {
      sessions: [],
      selectedDateFrom: null,
      showDateFromPicker: false,
      selectedDateTo: null,
      showDateToPicker: false,
      pagination: {
        itemsPerPageOptions: [25, 50, 100, -1],
        serverItemsLength: 0
      },
      tableOptions: {
        itemsPerPage: 25,
        page: 1
      },
      initialLoad: true,
      tableLoading: false
    };
  },
  watch: {
    //Necessary to update the filters when the user uses the browser back/forward button also to update filters when the user refresh the page
    '$route.query':{ 
        handler(params) {
            this.routeFilters(params)
            this.onFilter()
        },
        immediate: true
    },
  },
  methods: {
    async loadSessions() {
      let queryParams = `offset=${(this.tableOptions.itemsPerPage * this.tableOptions.page) - this.tableOptions.itemsPerPage}`
      if (this.tableOptions.itemsPerPage > 0) { queryParams += `&limit=${this.tableOptions.itemsPerPage}` }

      await this.$api.get(`provider/${this.providerId}/sessions${this.hasRole('CPO') ? `?role=CPO`:'?role=EMSP'}&${queryParams}`)
        .then( resp => {
            if( Array.isArray(resp.data) ){
                this.pagination.serverItemsLength = Number(resp.headers['x-total-count'])
                this.sessions = resp.data.map(session => {
                return {
                    id: session.id,
                    start_date_time: session.start_date_time,
                    end_date_time: session.end_date_time,
                    sender_id: session.sender_provider_id,
                    sender_name: session.senderProvider?.user?.username,
                    receiver_id: session.receiver_provider_id,
                    receiver_name: session.receiverProvider?.user?.username,
                    ocpi_id: session.ocpi_id,
                    location_id: session.location.id,
                    location_name: session.location.name,
                    chargepoint_id: session.evse.chargepoint.id,
                    chargepoint_name: session.evse.chargepoint.reference_name,
                    evse_uid: session.evse.uid,
                    kwh: session.kwh,
                    dtm_session_id: session.dtm_session_id,
                    last_updated: session.last_updated,
                    receiverProvider: session.receiverProvider,
                    senderProvider: session.senderProvider
                    }
                })
            }     
        })
        .catch(this.showError)
        .finally(() => {
            this.tableLoading = false
            this.initialLoad = false
        })
    },
    hasRole(role){
            return this.providerRole && this.providerRole.includes(role)
    },
    updateTableOptions(args){
        this.tableOptions = args
        if(!this.initialLoad){
            this.loadSessions()
        }
    },
    async onFilter(){
        if(this.tableLoading) return
        this.urlPush('dateFrom', this.selectedDateFrom)
        this.urlPush('dateTo', this.selectedDateTo)
        await this.loadSessions()

        const dateFormater = this.$options.filters.dateFormat
        if (this.selectedDateFrom) {
            this.sessions = this.sessions.filter(session => dateFormater(session.start_date_time) >= dateFormater(this.selectedDateFrom))
        }

        if (this.selectedDateTo) {
            this.sessions = this.sessions.filter(session => dateFormater(session.start_date_time) <= dateFormater(this.selectedDateTo))
        }
    },
  },
};
</script>