import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c('AppBar',{attrs:{"title":"Location Groups","icon":"mdi-folder-marker"}}),_c(VRow,{staticClass:"filer-row"},[_c(VCol,{attrs:{"cols":"12","md":"2"}},[_c(VTextField,{staticClass:"search-field",attrs:{"color":"accent","placeholder":"Search","autofocus":"","outlined":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onFilter.apply(null, arguments)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c(VBtn,{attrs:{"color":"accent","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.onFilter.apply(null, arguments)}}},[_c(VIcon,[_vm._v("mdi-magnify")])],1)]},proxy:true}]),model:{value:(_vm.selectedSearch),callback:function ($$v) {_vm.selectedSearch=$$v},expression:"selectedSearch"}})],1),_c(VBtn,{attrs:{"loading":_vm.tableLoading,"color":"accent","text":""},on:{"click":_vm.onFilter}},[_vm._v(" SEARCH ")])],1),(_vm.groups)?_c(VDataTable,{staticClass:"accent-table rounded-table",attrs:{"headers":[
            { value: 'linkto', sortable: false, cellClass: 'td-linkto' },
            { text: 'Id.', value: 'id' },
            { text: 'Name', value: 'name' },
            { text: 'Reference', value: 'reference' },
            { text: 'Private', value: 'is_private' },
            { text: 'Backend Id', value: 'backend_id' },
            { text: 'Updated at', value: 'updated_at' } ],"items":_vm.groups,"loading":_vm.tableLoading,"search":_vm.tableSearch,"disable-sort":"","disable-pagination":"","hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.linkto",fn:function(ref){
        var item = ref.item;
return [_c(VBtn,{attrs:{"plain":"","small":"","to":("/location-group/" + (item.id))}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-arrow-top-right")])],1)]}},{key:"item.is_private",fn:function(ref){
        var item = ref.item;
return [(item.is_private)?_c(VIcon,[_vm._v("mdi-check")]):_vm._e()]}}],null,false,535331729)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }