<template>
  <v-container fluid>
    <AppBar title="Tariffs" icon="mdi-tag-text-outline" />

        <v-card :loading="loading" outlined class="pb-4">

            <div v-if="tariff" class="pt-3 pr-3" style="position: absolute; right: 0; z-index: 10;">
                <v-menu offset-y rounded="lg">
                    <template v-slot:activator="{ on, attrs }">

                        <template v-if="$vuetify.breakpoint.name == 'xl' || (tariff && !tariff.type)">
                            <v-btn
                                color="accent"
                                text
                                v-bind="attrs"
                                v-on="on"
                            >
                                Actions<v-icon rigth>mdi-menu-down</v-icon>
                            </v-btn>
                        </template>

                        <template v-else>
                            <v-btn
                                color="accent"
                                icon
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon rigth>mdi-dots-horizontal</v-icon>
                            </v-btn>
                        </template>

                    </template>
                    <v-list class="pa-0">

                        <v-list-item link
                            :to="`/tariffs/${tariff.id}/edit`"
                            :disabled="!!tariff.deleted_at"
                        >
                            <v-list-item-title>
                                <v-icon left color="accent" :disabled="!!tariff.deleted_at">mdi-pencil</v-icon>Modify
                            </v-list-item-title>
                        </v-list-item>

                        <v-list-item link
                            @click="duplicate"
                        >
                            <v-list-item-title>
                                <v-icon left color="accent">mdi-content-copy</v-icon>Duplicate
                            </v-list-item-title>
                        </v-list-item>

                        <v-list-item v-if="!tariff.deleted_at" link
                            @click="deleteTariff(tariff)"
                        >
                            <v-list-item-title>
                                <v-icon left color="accent">mdi-delete</v-icon>Remove
                            </v-list-item-title>
                        </v-list-item>

                        <v-list-item v-else link
                            @click="unremoveTariff(tariff)"
                        >
                            <v-list-item-title>
                                <v-icon left color="accent">mdi-undo</v-icon>Unremove
                            </v-list-item-title>
                        </v-list-item>

                    </v-list>
                </v-menu>
            </div>

            <v-row v-if="tariff">
                <v-col v-if="tariff.deleted_at" cols="12" md="12" class="mt-3 ml-3 pb-0">
                     <v-chip outlined color="error">REMOVED</v-chip>
                </v-col>
                <v-col cols="12" lg="2">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>{{ tariff.id }}</v-list-item-title>
                            <v-list-item-subtitle>Id.</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col cols="12" lg="2">
                    <v-list-item two-line>
                        <v-list-item-content style="overflow: visible;">
                            <v-list-item-title>
                                <ProviderName
                                    :provider="{ id: tariff.provider_id, country_code: tariff.country_code, party_id: tariff.party_id }"
                                    type="title"
                                />
                            </v-list-item-title>
                            <v-list-item-subtitle>Provider</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col cols="12" lg="4" xl="2">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>{{ tariff.name ? tariff.name : '-' }}</v-list-item-title>
                            <v-list-item-subtitle>Name</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col cols="12" lg="4" xl="3">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>{{ tariff.ocpi_id }}</v-list-item-title>
                            <v-list-item-subtitle>OCPI Id.</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col cols="12" lg="2">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>{{ tariff.type ? tariff.type : '-' }}</v-list-item-title>
                            <v-list-item-subtitle>Type</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col cols="12" md="2">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>{{ tariff.currency }}</v-list-item-title>
                            <v-list-item-subtitle>Currency</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col cols="12" md="2">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>{{ tariff.min_price ? tariff.min_price : '-' }}</v-list-item-title>
                            <v-list-item-subtitle>Min price</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col cols="12" md="2">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>{{ tariff.max_price ? tariff.max_price : '-' }}</v-list-item-title>
                            <v-list-item-subtitle>Max price</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col cols="12" md="4">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>{{ tariff.tariff_alt_url ? tariff.tariff_alt_url : '-' }}</v-list-item-title>
                            <v-list-item-subtitle>Url</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                    <span class="text-caption ml-3">Elements</span>
                    <template v-if="tariff.elements && tariff.elements.a && tariff.elements.a.length > 0">
                        <v-list shaped>
                            <v-list-item v-for="(element, index) in tariff.elements.a" :key="index">
                                <v-list-item-content>
                                    <v-row class="ml-0">
                                        <span class="mt-2">{{ index + 1 }} - </span>
                                        <PriceComponentChip v-for="(price_component, index) in element.price_components" 
                                            :key="index" class="ml-2" 
                                            outlined color="accent"
                                            :priceComponent="price_component"
                                            :currency="tariff.currency"
                                        />
                                        <div v-for="(value, key, index) in element.restrictions" :key="`res-${index}`" style="width=auto;" class="pt-1">
                                            <span class="text-caption ml-3">{{ key }}: </span><span>{{ value }}</span>
                                        </div>
                                    </v-row>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </template>
                    <v-row v-else class="ml-4 pt-2 pb-4">
                        <span>-</span>
                    </v-row>
                </v-col>
                <v-col cols="12" md="6" class="pt-0">
                    <span class="text-caption ml-3">Description</span>
                    <template v-if="tariff.tariff_alt_text && tariff.tariff_alt_text.a && tariff.tariff_alt_text.a.length > 0">
                        <v-list shaped>
                            <v-list-item v-for="(tariff_alt_text, index) in tariff.tariff_alt_text.a" :key="index">
                                <v-list-item-content>
                                    <v-row class="ml-0">
                                        <span class="mt-2">{{ index + 1 }} - </span>
                                        <v-chip class="ml-3" outlined color="accent">{{ tariff_alt_text.language }}</v-chip>
                                        <span class="ml-3 mt-2">{{ tariff_alt_text.text }}</span>
                                    </v-row>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </template>
                    <v-row v-else class="ml-4 pt-2 pb-4">
                        <span>-</span>
                    </v-row>
                </v-col>
            </v-row>                
        </v-card>

        <v-card v-if="tariff && tariff.provider_id == 1"
            class="mt-3"
            outlined
        >
            <h4 class="pt-3 pl-4">Configurations</h4>
            <tariff-configurations v-model="tariff.tariff_configuration" :disabled="!!tariff.deleted_at"></tariff-configurations>
      </v-card>
    
      <TariffEditDialog />
  </v-container>
</template>

<script>
import AppBar from "@/components/AppBar"
import TariffConfigurations from "@/components/tariff/TariffConfigurations";
import ProviderName from "@/components/provider/ProviderName"
import PriceComponentChip from "@/components/tariff/PriceComponentChip"
import TariffEditDialog from "@/components/tariff/TariffEditDialog"
import { EventBus } from "@/event-bus";

export default {
    components: {
      AppBar,
      TariffConfigurations,
      ProviderName,
      PriceComponentChip,
      TariffEditDialog
    },
    data() {
      return {
          loading: false,
          tariff: null
      }
    },
    async mounted(){
      this.loadTariff()      
    },
    watch: {
        $route(to, from) {
            if (to.params.id != from.params.id) {
                this.loadTariff()
            }
        }
    },
    methods: {
        loadTariff(){
            let id = this.$router.currentRoute.params.id
            if( !id ){ return; }

            this.loading=true
            this.$api.get(`/tariffs/${id}`)
                .then(resp => this.tariff = resp.data)
                .catch(this.showError)
                .then(() => this.loading = false)
        },
        deleteTariff(data){
            this.$api.delete(`/tariffs/${data.id}`)
                .then(() => {
                    this.loadTariff()
                    this.showSuccess("Tariff deleted")
                })
                .catch(this.showError)
        },
        unremoveTariff(data){
            const tariff = { ...data, deleted_at: null  }
            delete tariff.tariff_configuration

            this.$api.put(`/tariffs/${data.id}`, tariff)
                .then(async () => {
                    this.loadTariff()
                    this.showSuccess("Changes successfully saved")
                    this.$api.put(`/tariffs/${data.id}/relations`)
                })
                .catch(this.showError)
        },
        duplicate(){
            const tariff = (({id, ocpi_id, ...tariff}) => tariff) (this.tariff)
            const tariff_configuration = (({id, ...tariff_configuration}) => tariff_configuration) (this.tariff.tariff_configuration)
            const duplicatedTariff = {...tariff, tariff_configuration}
            EventBus.$emit("tariff-edit-dialog:open", duplicatedTariff)
        }
    }
}
</script>