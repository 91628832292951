import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c('AppBar',{attrs:{"title":"Pending locations export to Core","icon":"mdi-database-export"}}),_c(VRow,{staticClass:"filer-row"},[_c(VCol,{attrs:{"cols":"12","md":"2"}},[_c(VAutocomplete,{staticClass:"filter-field",attrs:{"items":_vm.providers,"label":"Provider","color":"accent","outlined":"","clearable":"","hide-details":""},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('ProviderSelectorTemplate',{attrs:{"provider":data.item}})]}}]),model:{value:(_vm.selectedProvider),callback:function ($$v) {_vm.selectedProvider=$$v},expression:"selectedProvider"}})],1),_c(VCol,{attrs:{"cols":"12","md":"2"}},[_c(VAutocomplete,{staticClass:"filter-field",attrs:{"items":_vm.countries,"label":"Country","item-text":"name","item-value":"alpha3","color":"accent","clearable":"","outlined":"","hide-details":""},model:{value:(_vm.selectedCountry),callback:function ($$v) {_vm.selectedCountry=$$v},expression:"selectedCountry"}})],1),_c(VCol,{attrs:{"cols":"12","md":"2"}},[_c(VTextField,{staticClass:"filter-field",attrs:{"type":"number","label":"Minimum CP power (kW)","outlined":"","clearable":"","hide-details":""},model:{value:(_vm.selectedMinimumChargerPower),callback:function ($$v) {_vm.selectedMinimumChargerPower=_vm._n($$v)},expression:"selectedMinimumChargerPower"}})],1),_c(VBtn,{attrs:{"loading":_vm.tableLoading,"color":"accent","text":""},on:{"click":_vm.onFilter}},[_vm._v(" SEARCH ")])],1),_c(VDataTable,{staticClass:"accent-table rounded-table",attrs:{"headers":[
            { text: 'Provider', value: 'provider' },
            { text: 'Id.', value: 'id' },
            { text: 'Name', value: 'name' },
            { text: 'Connectors', value: 'max_electric_powers' },
            { text: ("Connector greater than " + (_vm.locationsMinimumChargerPower || 0) + " kW"), value: 'connector_greater_than_filter' },
            { text: '', value: 'actions' } ],"items":_vm.locations,"loading":_vm.tableLoading,"disable-sort":"","dense":"","no-data-text":_vm.initialLoadMessage},scopedSlots:_vm._u([{key:"item.provider",fn:function(ref){
        var item = ref.item;
return [_c('ProviderName',{attrs:{"provider":{ id: item.provider_id, country_code: item.country_code, party_id: item.party_id }}})]}},{key:"item.id",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" "),_c(VBtn,{staticClass:"pl-0",staticStyle:{"min-width":"0px"},attrs:{"plain":"","small":"","to":("/location/" + (item.id))}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-arrow-top-right")])],1)]}},{key:"item.max_electric_powers",fn:function(ref){
        var item = ref.item;
return [_c(VChip,{attrs:{"outlined":"","color":"accent","small":""}},[_c(VIcon,{attrs:{"small":"","left":""}},[_vm._v("mdi-power-plug")]),_vm._v(" "+_vm._s(item.max_electric_powers.length)+" ")],1)]}},{key:"item.connector_greater_than_filter",fn:function(ref){
        var item = ref.item;
return [_c(VChip,{attrs:{"outlined":"","color":"accent","small":""}},[_c(VIcon,{attrs:{"small":"","left":""}},[_vm._v("mdi-power-plug")]),_vm._v(" "+_vm._s(item.max_electric_powers.filter(function (p) { return p >= _vm.selectedMinimumChargerPower; }).length)+" ")],1)]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mr-2",attrs:{"label":"Provider","color":"accent","text":"","depressed":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.sendLocation(item.id)}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-send")])],1)]}}],null,true)},[_c('span',[_vm._v("Send location to provider")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }