import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,{staticClass:"filer-row"},[_c(VSpacer),_c(VCol,{attrs:{"cols":"12","md":"2"}},[_c(VTextField,{staticClass:"search-field",attrs:{"placeholder":"Buscar","color":"accent","outlined":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onFilter.apply(null, arguments)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c(VBtn,{attrs:{"color":"accent","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.onFilter.apply(null, arguments)}}},[_c(VIcon,[_vm._v("mdi-magnify")])],1)]},proxy:true}]),model:{value:(_vm.selectedSearch),callback:function ($$v) {_vm.selectedSearch=$$v},expression:"selectedSearch"}})],1),_c(VBtn,{attrs:{"loading":_vm.loading,"color":"accent","text":""},on:{"click":_vm.onFilter}},[_vm._v(" SEARCH ")])],1),_c(VDataTable,{staticClass:"accent-table rounded-table",attrs:{"headers":[
      { value: 'action', sortable:false, cellClass: 'td-linkto' },
      { text: 'OCPI Id.', value: 'ocpi_id' },
      { text: 'Name', value: 'name' },
      { text: 'City', value: 'city' },
      { text: 'EVSEs', value: 'evses.length' },
      { text: 'Core Id', value: 'backend_id' },
      { text: 'Updated', value: 'updated_at' },
      { text: 'Deleted', value: 'deleted_at' }
    ],"items":_vm.locations,"items-per-page":_vm.tableOptions.itemsPerPage,"server-items-length":_vm.tablePagination.serverItemsLength,"loading":_vm.loading,"footer-props":{
      'items-per-page-options': _vm.tablePagination.itemsPerPageOptions,
    },"disable-sort":"","dense":""},on:{"update:options":this.updateTableOptions},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_c(VBtn,{attrs:{"plain":"","small":"","to":("/location/" + (item.id))}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-arrow-top-right")])],1)]}},{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('LocationName',{attrs:{"location":item,"extra":"no-arrow","type":"table"}})]}}])}),_c('ImportLocationsFromProvider',{staticClass:"mt-2",attrs:{"provider-id":_vm.providerId},on:{"data-imported":function($event){return _vm.getLocations()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }