<template>

    <v-app-bar app dense elevation="0" class="accent-app-bar">

        <v-app-bar-nav-icon color="grey darken-4" @click.stop="$store.commit('toggle_navigator')"></v-app-bar-nav-icon>

        <div v-if="items" class="ml-sm-4">
            <v-breadcrumbs :items="items" class="flex-nowrap">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item :to="item.to" exact-active-class="display-1" active-class="display-1">
                        <v-icon v-if="item.icon" left color="accent">{{ item.icon }}</v-icon>
                        <span class="overflow-hidden">{{ item.text }}</span>
                    </v-breadcrumbs-item>
                </template>
                <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                </template>
            </v-breadcrumbs>
        </div>

        <div class="flex-grow-1"></div>

        <v-spacer></v-spacer>
        <div v-if="actions">
            <v-btn icon>
                <v-icon>mdi-magnify</v-icon>
            </v-btn>
        </div>

        <!-- Searcher -->
        <div>
            <OcpiIdSearcherTool type="appBar"/>
        </div>

        <!-- Alerts -->
        <div v-if="$store.state.alerts" class="ml-4 d-flex">
            <v-divider class="border-opacity-50 mr-2" vertical></v-divider>
            <AlertsButton />
        </div>
        
    </v-app-bar>

</template>

<script>
import OcpiIdSearcherTool from "@/components/OcpiIdSearcherTool";
import AlertsButton from "@/components/ui-components/AlertsButton"

export default {
    name: "app-bar",
    components: { AlertsButton, OcpiIdSearcherTool },
    props: ['title', 'icon', 'breadcrumbs', 'actions'],
    computed: {
        items: function(){
            let items = []
            if( this.title ){
                items.push({ text: this.title, icon: this.icon })
                this.setTitle(`${this.title}`)
            }
            if( this.breadcrumbs ){
                items.push(...this.breadcrumbs)

                //Get breadcrumbs text and join with space
                const title = items.map(item => item.text).join(' ')
                this.setTitle(`${title}`)
            }
            return items
        }
    }
}
</script>

<style scope>
    .v-breadcrumbs a:hover, .v-breadcrumbs a:hover .v-icon { color: #000 !important }
    .v-breadcrumbs li:last-child { font-size: 24px; font-weight: 600; }
    .v-breadcrumbs li:last-child .v-icon { font-size: 26px; }
</style>