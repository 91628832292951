import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"1180px"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[(_vm.provider)?_c(VCard,[_c(VCardTitle,{staticClass:"accent white--text"},[_c('span',{staticClass:"headline"},[_vm._v("Add group to Provider "+_vm._s(_vm.providerName))])]),(_vm.provider.id)?_c(VCardText,{staticClass:"pa-4"},[_c(VSelect,{attrs:{"return-object":"","items":_vm.groups,"item-text":"name","color":"accent","label":"Elegir el grupo","outlined":""},model:{value:(_vm.selectedGroup),callback:function ($$v) {_vm.selectedGroup=$$v},expression:"selectedGroup"}}),(_vm.selectedGroup)?_c('div',{staticClass:"title mb-2"},[_vm._v(" Add group "),_c(VChip,{attrs:{"outlined":"","color":"accent"}},[_vm._v(_vm._s(_vm.selectedGroup.name))]),_vm._v(" to "),_c(VChip,{attrs:{"outlined":"","color":"accent"}},[_vm._v(_vm._s(_vm.providerName))]),_vm._v(" ? ")],1):_vm._e()],1):_vm._e(),_c(VCardActions,[(_vm.selectedGroup)?_c(VBtn,{attrs:{"depressed":"","color":"accent","loading":_vm.actionLoading},on:{"click":_vm.addGroup}},[_vm._v("Continue")]):_vm._e(),_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.show = false}}},[_vm._v("Cancel")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }