 <template>
    <div>
        <v-row class="filer-row mt-3">
            
            <v-spacer />

            <v-col cols="12" md="2">
                <v-text-field
                    class="search-field"
                    v-model="selectedSearch"
                    placeholder="Search"
                    color="accent"
                    autofocus
                    outlined
                    hide-details
                    @keyup.enter="onFilter"
                >
                    <template v-slot:append>
                        <v-btn
                            color="accent" 
                            text
                            @click.stop="onFilter"
                        >
                            <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                    </template>
                </v-text-field>
            </v-col>
            
            <v-btn 
                :loading="commandsLoading" 
                @click="onFilter"
                color="accent"
                text
            > 
                SEARCH
            </v-btn>
        </v-row>

        <!-- Data table -->
        <v-data-table
            :headers="[
                { value: 'action', sortable: false, width: '10px' },
                { text: 'Date', value: 'created_at' },
                { text: 'Command', value: 'command' },
                { text: 'Provider', value: 'provider' },
                { text: 'Request', value: 'req_success' },
                { text: 'Response', value: 'resp_success' },
                { text: 'Result', value: 'res_success' },
            ]"
            :items="commands"
            :search="tableSearch"
            disable-sort
            :loading="commandsLoading"
            loading-text="Getting data..."
            dense
            class="accent-table rounded-table"       
        >
            <template v-slot:item.action="{ item }">
                <v-btn
                    plain
                    small
                    :to="`/command/${item.command}/${item.command_id}`"
                >
                    <v-icon small>mdi-arrow-top-right</v-icon>
                </v-btn>
            </template>
            <template v-slot:item.command="{ item }">
                {{ item.command }}
                <v-chip class="ml-1 text-chip text-uppercase px-2" small label outlined>
                    # {{ item.command_id }}
                </v-chip>
            </template>
            <template v-slot:item.provider="{ item }">
                <ProviderName
                    :provider="{ id: item.provider_id, country_code: item.provider_country, party_id: item.provider_party }"
                />
            </template>
            <template v-slot:item.req_success="{ item }">
                <v-icon v-if="item.req_success" color="success"
                    >mdi-check-circle</v-icon
                >
                <v-icon v-else color="error">mdi-close-circle</v-icon>
            </template>
            <template v-slot:item.resp_success="{ item }">
                <v-icon v-if="item.resp_success" color="success"
                    >mdi-check-circle</v-icon
                >
                <v-icon v-else color="error">mdi-close-circle</v-icon>
            </template>
            <template v-slot:item.res_success="{ item }">
                <v-icon v-if="item.res_success" color="success"
                    >mdi-check-circle</v-icon
                >
                <v-icon v-else color="error">mdi-close-circle</v-icon>
            </template>
            <template v-slot:item.created_at="{ item }">
                {{ item.created_at | dateFullFormat }}
            </template>
        </v-data-table>
    </div>
</template>
<script>
import ProviderName from "@/components/provider/ProviderName"

export default {
    name: "provider-commands",
    components: {
        ProviderName
    },
    props: ["providerId", "role"],

    data() {
        return {
            selectedSearch: null,
            commands: [],
            commandsLoading: false,
            tableSearch: null,
        };
    },
    mounted() {
        this.load();
    },
    watch: {
        //Necessary to update the filters when the user uses the browser back/forward button also to update filters when the user refresh the page
        '$route.query':{ 
            handler(params) {
                this.routeFilters(params)
                this.onFilter()
            },
            immediate: true
        },
    },
    methods: {
        load() {
            this.commandsLoading = true;
            this.$api
                .get(`provider/${this.providerId}/commands/${this.role}`)
                .then((resp) => this.commands = resp.data)
                .catch(this.showError)
                .then((_) => {
                    this.commandsLoading = false;
                });
        },
        onFilter(){
            if(this.commandsLoading) return
            this.tableSearch = this.selectedSearch
            this.urlPush('search', this.selectedSearch)

            this.load()

        },
    },
};
</script>