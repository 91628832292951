import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"1180px"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VCardTitle,{staticClass:"accent white--text"},[_c('span',{staticClass:"headline"},[_vm._v("Not sent Locations")])]),_c(VCardText,{staticClass:"pa-4"},[_c(VRow,{staticClass:"filer-row"},[_c(VSpacer),_c(VCol,{attrs:{"cols":"12","md":"2"}},[_c(VTextField,{staticClass:"filter-field",attrs:{"hide-details":"","append-icon":"mdi-magnify","placeholder":"Search","color":"accent","outlined":""},model:{value:(_vm.tableSearch),callback:function ($$v) {_vm.tableSearch=$$v},expression:"tableSearch"}})],1),_c(VBtn,{attrs:{"text":"","color":"accent"},on:{"click":function($event){return _vm.sendAllLocation()}}},[_vm._v(" Send all "),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-send")])],1)],1),_c(VDataTable,{attrs:{"headers":[
                    { text: 'Id.', value: 'id' },
                    { text: 'OCPI Id.', value: 'ocpi_id' },
                    { text: 'Name', value: 'name' },
                    { text: 'EVSEs', value: 'evses.length' },
                    { text: 'City', value: 'city' },
                    { text: 'Last updated', value: 'last_updated' },
                    { text: 'Actions', value: 'actions', sortable: false }
                ],"items":_vm.locations,"search":_vm.tableSearch,"loading":_vm.loading,"disable-sort":"","dense":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c(VBtn,{staticClass:"mr-2",attrs:{"color":"accent","depressed":"","small":""},on:{"click":function($event){return _vm.sendLocation(item)}}},[_vm._v("Send")])]}}])})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.show = false}}},[_vm._v("Close")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }