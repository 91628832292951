<template>
    <v-container fluid>

        <AppBar icon="mdi-console-line" title="Commands" />

        <v-row class="filer-row">
            <v-col cols="12" md="2">
                <v-text-field 
                    v-model="selectedSearch"  
                    class="search-field"
                    placeholder="Search"
                    color="accent"
                    autofocus
                    outlined
                    hide-details
                    @keyup.enter="onFilter"
                >
                    <template v-slot:append>
                        <v-btn
                            color="accent" 
                            text
                            @click.stop="onFilter"
                        >
                            <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                    </template>
                </v-text-field>
            </v-col>

            <v-btn 
                :loading="commandsLoading" 
                @click="onFilter"
                color="accent"
                text
            >
                SEARCH
            </v-btn>

            <v-spacer />

            <v-btn 
                color="accent"
                :loading="loadingCSV"
                outlined 
                @click="createCSV"
            >
                <v-icon left>mdi-file-download-outline</v-icon> CSV
            </v-btn>
        </v-row>

        <!-- Data table -->
        <v-data-table
            :headers="[
                { value: 'action', sortable:false, cellClass: 'td-linkto' },
                {text: 'Date', value: 'created_at'},
                {text: 'Command', value: 'command'},
                {text: 'Provider', value: 'provider'},
                {text: 'Location', value: 'location'},
                {text: 'Chargepoint', value: 'chargepoint'},
                {text: 'Request', value: 'req_success'},
                {text: 'Response', value: 'resp_success'},
                {text: 'Result', value: 'res_success'}
            ]"
            :items="commands"
            disable-sort
            :loading="commandsLoading"
            loading-text="Getting data..."
            dense
            class="accent-table rounded-table"         
        >
            <template v-slot:item.action="{ item }">
            <v-btn plain
                small
                :to="`/command/${item.command}/${item.command_id}`" 
                >
                <v-icon small>mdi-arrow-top-right</v-icon>
            </v-btn>
            </template>
            <template v-slot:item.command="{ item }">
                {{ item.command }}
                <v-chip class="ml-1 text-chip text-uppercase px-2" small label outlined>
                    # {{ item.command_id }}
                </v-chip>
            </template>
            <template v-slot:item.provider="{ item }">
                <ProviderName
                    :provider="{ id: item.provider_id, country_code: item.provider_country, party_id: item.provider_party }"
                    role="emsp"
                />
            </template>
            <template v-slot:item.location="{ item }">
                <LocationName
                    v-if="item.name"
                    :location="{ id: item.location_id, name: item.name }"
                    type="table"
                />
            </template>
            <template v-slot:item.chargepoint="{ item }">
                <ChargepointName
                    :chargepoint="{ id: item.chargepoint_id, name: item.reference_name }"
                    type="table"
                />
            </template>
            <template v-slot:item.req_success="{ item }">
                <v-icon v-if="item.req_success" color="success">mdi-check-circle</v-icon>
                <v-icon v-else color="error">mdi-close-circle</v-icon>
            </template>
            <template v-slot:item.resp_success="{ item }">
                <v-icon v-if="item.resp_success" color="success">mdi-check-circle</v-icon>
                <v-icon v-else color="error">mdi-close-circle</v-icon>
            </template>
            <template v-slot:item.res_success="{ item }">
                <v-icon v-if="item.res_success" color="success">mdi-check-circle</v-icon>
                <v-icon v-else color="error">mdi-close-circle</v-icon>
            </template>
            <template v-slot:item.created_at="{ item }">
                {{ item.created_at | dateFullFormat }}
            </template>
        </v-data-table>

    </v-container>
</template>
<script>
import AppBar from "@/components/AppBar"
import ProviderName from "@/components/provider/ProviderName"
import LocationName from "@/components/location/LocationName"
import ChargepointName from "@/components/chargepoint/ChargepointName"

export default {
    name: "wenea",
    components: {
        AppBar,
        ProviderName,
        LocationName,
        ChargepointName
    },
    data() {
        return {
            selectedSearch: undefined,
            commands: [],
            commandsLoading: false,
            loadingCSV: false
        }
    },
    watch: {
        //Necessary to update the filters when the user uses the browser back/forward button also to update filters when the user refresh the page
        '$route.query':{ 
            handler(params) {
                this.routeFilters(params)
                this.onFilter()
            },
            immediate: true
        },
    },
    methods: {
        async load(){
            this.commandsLoading = true

            await this.$api.get('commands')
                .then(resp => this.commands = resp.data.rows)
                .catch(this.showError)
                .then(_ => { 
                    this.commandsLoading = false
                })
        },
        async onFilter(){
            if(this.commandsLoading) return
            this.urlPush('search', this.selectedSearch)

            await this.load()

            if(this.selectedSearch){
                this.commands = this.commands.filter(item => {
                    return item.command.toLowerCase().includes(this.selectedSearch.toLowerCase())
                })
            }
        },
        async createCSV(){
            this.loadingCSV = true

            let content = '"Id","Date","Command","Provider","Request","Response","Result"\r\n'
            
            for( const row of this.commands ){
                content += `"${row.command_id}","${row.created_at}","${row.command}","${row.provider_country}-${row.provider_party}","${row.req_success}","${row.resp_success}","${row.res_success}"\r\n`
            }

            this.downloadBlob(content, `commands.csv`, "text/csv")
            this.loadingCSV = false
        }
    }
}
</script>