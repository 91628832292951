import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VTabs,{attrs:{"vertical":""}},[_vm._l((_vm.jobsToShow),function(job){return _c(VTab,{key:job.taskName,staticClass:"justify-start",attrs:{"active-class":"active-tab"}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-file-tree")]),_vm._v(_vm._s(job.title)+" ")],1)}),_vm._l((_vm.jobsToShow),function(job){return _c(VTabItem,{key:job.taskName},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('div',{staticClass:"mb-6",domProps:{"innerHTML":_vm._s(job.description)}}),_c(VRow,[_c(VTextField,{staticClass:"px-3 pb-6",attrs:{"clearable":"","hide-details":"","outlined":"","dense":"","color":"accent","append-icon":"mdi-magnify","placeholder":"Search"},model:{value:(_vm.tableSearch[job.taskName]),callback:function ($$v) {_vm.$set(_vm.tableSearch, job.taskName, $$v)},expression:"tableSearch[job.taskName]"}}),_c(VBtn,{staticClass:"mr-3",attrs:{"elevation":"0","color":"accent","min-height":"40","outlined":"","loading":_vm.runJobLoading},on:{"click":function($event){return _vm.runJob(job.taskName)}}},[_vm._v(" Run job ")])],1),(_vm.cronjobs)?_c(VDataTable,{staticClass:"jobs-table",attrs:{"headers":[
                            {text: 'Success', value: 'success', align: 'center' },
                            {text: 'Job', value: 'job', align: 'center'},
                            {text: 'Provider/Connection', value: 'provider', align: 'center'},
                            {text: 'Error response', value: 'error_response', align: 'center'},
                            {text: 'Start time', value: 'start_at', align: 'center'},
                            {text: 'End time', value: 'end_at', align: 'center'} ],"items":_vm.cronjobs.filter(function (cronjob) { return cronjob.task_name == job.taskName; }),"custom-filter":_vm.dataTableDeepSearch,"search":_vm.tableSearch[job.taskName],"sort-by":"created_at","sort-desc":""},scopedSlots:_vm._u([{key:"item.success",fn:function(ref){
                        var item = ref.item;
return [(item.success)?_c(VIcon,{attrs:{"color":"success"}},[_vm._v("mdi-check-circle")]):_c(VIcon,{attrs:{"color":"error"}},[_vm._v("mdi-close-circle")])]}},{key:"item.job",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(item.task_name)+" #"+_vm._s(item.id)+" ")]}},{key:"item.provider",fn:function(ref){
                        var item = ref.item;
return [(item.user_id)?_c('ProviderName',{attrs:{"provider":{ applicable_user: item.user_id ,username: item.user_company_name },"extra":"applicable_user"}}):_vm._e(),(item.provider_id)?_c('ProviderName',{attrs:{"provider":{ id: item.provider_id, country_code: item.provider_country_code, party_id: item.provider_party_id }}}):_vm._e()]}},{key:"item.error_response",fn:function(ref){
                        var item = ref.item;
return [(item.error_response)?_c('json-viewer',{staticClass:"pa-2 text-left",attrs:{"value":item.error_response,"expand-depth":0}}):_vm._e()]}},{key:"item.start_at",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFullFormat")(item.start_at))+" ")]}},{key:"item.end_at",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFullFormat")(item.end_at))+" ")]}}],null,true)}):_vm._e()],1)],1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }