<template>
  <div>  
    <v-row class="filer-row">
      <v-spacer></v-spacer>

      <v-col cols="12" md="2">
          <v-text-field 
            class="search-field"
            v-model="selectedUid" 
            placeholder="UID"
            color="accent"
            outlined
            hide-details
            @keyup.enter="onFilter"
          >
            <template v-slot:append>
              <v-btn
                  color="accent" 
                  text
                  @click.stop="onFilter"
              >
                  <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
          </v-text-field>
      </v-col>

      <v-col cols="12" md="2">
        <v-select
          class="filter-field"
          v-model="selectedType"
          :items="[
              { value: 'OTHER', text: 'OTHER' },
              { value: 'RFID', text: 'RFID' },
              { value: 'APP_USER', text: 'APP_USER' },
              { value: 'AD_HOC_USER', text: 'AD_HOC_USER' },
          ]"
          label="Type"
          clearable
          outlined
          hide-details
          color="accent"
        >
        </v-select>
      </v-col>

      <v-col cols="12" md="2">
        <v-select
          class="filter-field"
          v-model="selectedValid"
          :items="[
              { value: true, text: 'Valid' },
              { value: false, text: 'Invalid' },
          ]"
          label="Valid"
          color="accent"
          clearable
          outlined
          hide-details
        >
          <template v-slot:selection="{ item }">
            <v-icon v-if="item.value" color="success" left>mdi-check-circle</v-icon>
            <v-icon v-else color="error" left>mdi-close-circle</v-icon>
            <span>{{item.text}}</span>
          </template>
          <template v-slot:item="{ item }">
            <v-icon v-if="item.value" color="success" left>mdi-check-circle</v-icon>
            <v-icon v-else color="error" left>mdi-close-circle</v-icon>
            <span>{{item.text}}</span>
          </template>
        </v-select>
      </v-col>

      <v-col cols="12" md="2" xl="2">
        <v-menu
            ref="menu"
            class="filter-field"
            v-model="showDateFromPicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"    
          >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  v-on="on"
                  class="filter-field"
                  v-model="selectedDateFrom"
                  label="Date from"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  color="accent"
                  clearable
                  outlined
                  hide-details
                ></v-text-field>
            </template>
            <v-date-picker
                v-model="selectedDateFrom"
                @input="showDateFromPicker = false"
                no-title
            ></v-date-picker>
        </v-menu>
      </v-col>
       <v-col cols="12" md="2" xl="2">
            <v-menu
                ref="menu"
                class="filter-field"
                v-model="showDateToPicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"  
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      class="filter-field"
                      v-model="selectedDateTo"
                      label="Date to"
                      prepend-inner-icon="mdi-calendar"
                      color="accent"  
                      readonly
                      clearable
                      outlined
                      hide-details
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="selectedDateTo"
                    @input="showDateToPicker = false"
                    no-title
                ></v-date-picker>
            </v-menu>
        </v-col>

        <v-btn
          :loading="tableLoading"
          @click="onFilter"
          color="accent"
          text
        >
          SEARCH
        </v-btn>
    </v-row>
    
    <v-data-table 
        class="accent-table rounded-table hide-footer-pagination-items-count"
        :headers="[
            { text: 'UID', value: 'uid' },
            { text: 'Type', value: 'type' },
            { text: 'Valid?', value: 'valid' },
            { text: 'Auth id.', value: 'contract_id' },
            { text: 'Issuer', value: 'issuer' },
            { text: 'Updated at', value: 'updated_at' },
        ]"
        :items="ocpiTokens"
        :items-per-page="tableOptions.itemsPerPage"
        :server-items-length="ocpiTokens.length ? (tableOptions.page * ocpiTokens.length + 1) : 0"
        :loading="tableLoading"
        :footer-props="{
            'items-per-page-options': tablePagination.itemsPerPageOptions,
            'page-text': ''
        }"
        disable-sort
        sort-by="updated_at"
        @update:options="this.updateTableOptions"
        dense
    >
        <template slot="no-data">
            No data, try to click "Search" button
        </template>
      <template v-slot:item.valid="{ item }">
        <v-icon v-if="item.valid" color="success">mdi-check-circle</v-icon>
        <v-icon v-else color="error">mdi-close-circle</v-icon>
      </template>
      <template v-slot:item.updated_at="{ item }">
          {{ item.updated_at | dateFullFormat }}
      </template>
    </v-data-table>
    
    <v-card
      class="ma-2"
      elevation="0"
      outlined
    >
      <v-card-title>Import tokens from provider</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" lg="2" xl="1">
            <v-menu
              ref="menu"
              class="filter-field"
              v-model="checkTokens.showDateFromPicker"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      class="filter-field"
                      v-model="checkTokens.selectedDateFrom"
                      label="Date from"
                      prepend-inner-icon="mdi-calendar"
                      color="accent"
                      readonly
                      clearable
                      outlined
                      hide-details
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="checkTokens.selectedDateFrom"
                    @input="checkTokens.showDateFromPicker = false"
                    no-title
                ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" lg="2" xl="1">
            <v-menu
                ref="menu"
                class="filter-field"
                v-model="checkTokens.showDateToPicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      class="filter-field"
                      v-model="checkTokens.selectedDateTo"
                      label="Date to"
                      prepend-inner-icon="mdi-calendar"
                      color="accent"
                      readonly
                      clearable
                      outlined
                      hide-details
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="checkTokens.selectedDateTo"
                    @input="checkTokens.showDateToPicker = false"
                    no-title
                ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" lg="2" xl="1">
            <v-text-field
              class="filter-field"
              v-model="checkTokens.selectedOffset"
              type="number"
              label="Offset"
              color="accent"
              outlined
              hide-details
            ></v-text-field> 
          </v-col>

          <v-col cols="12" lg="2" xl="1">
            <v-text-field
              class="filter-field"
              v-model="checkTokens.selectedLimit"
              type="number"
              label="Limit"
              color="accent"
              outlined
              hide-details
            ></v-text-field> 
          </v-col>

          <v-col cols="12" lg="2" xl="1">
            <v-text-field
              class="filter-field"
              v-model="checkTokens.maxPages"
              type="number"
              label="Max pages"
              color="accent"
              outlined
              hide-details
            ></v-text-field> 
          </v-col>

          <v-col cols="12" lg="2" xl="1">
            <v-text-field
              class="filter-field"
              v-model="checkTokens.countryCode"
              type="string"
              label="Country code"
              color="accent"
              outlined
              hide-details
            ></v-text-field> 
          </v-col>

          <v-col cols="12" lg="2" xl="1">
            <v-text-field
              class="filter-field"
              v-model="checkTokens.partyId"
              type="string"
              label="Party id"
              color="accent"
              outlined
              hide-details
            ></v-text-field> 
          </v-col>

          <v-col cols="12" lg="2" xl="1">
            <v-btn
              :loading="checkTokens.loading"
              @click="updateOcpiTokens(checkTokens.selectedDateFrom, checkTokens.selectedDateTo, checkTokens.selectedOffset, checkTokens.selectedLimit, checkTokens.maxPages, checkTokens.countryCode, checkTokens.partyId)"
              color="accent"
              outlined
              block
            >
              Import<v-icon right>mdi-table-arrow-up</v-icon>
            </v-btn>
          </v-col>

          <v-col cols="12" lg="2" xl="1">
              <v-btn
                text @click="checkTokens.showReport = !checkTokens.showReport" block
              >
                Last report <v-icon right>{{ checkTokens.showReport ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
          </v-col>
        </v-row>
        
        <v-row>
          <transition name="slide-y-transition">
            <div v-if="checkTokens.showReport" class="ml-4 mb-2" style="">
              <v-btn text icon @click="showReportDialog"><v-icon>mdi-magnify</v-icon></v-btn>
              <v-chip class="ml-2" outlined color="blue">Updated {{ checkTokens.tokenCollectionReport.updated }}</v-chip>
              <v-chip class="ml-2" outlined color="green">Created {{ checkTokens.tokenCollectionReport.created }}</v-chip>
              <v-chip class="ml-2" outlined color="red">Errors {{ checkTokens.tokenCollectionReport.errors }}</v-chip>
              <v-chip class="ml-2" outlined><v-icon left color="grey">mdi-calendar</v-icon> {{ checkTokens.tokenCollectionReport.created_at | dateFullFormat }}</v-chip>
              <v-chip class="ml-2" outlined :color="checkTokens.tokenCollectionReport.success ? 'green' : 'red'">
                Finished <v-icon right :color="checkTokens.tokenCollectionReport.success ? 'green' : 'red'">{{ checkTokens.tokenCollectionReport.success ? 'mdi-check-circle' : 'mdi-close-circle' }}</v-icon>
              </v-chip>
            </div>
          </transition>
        </v-row>
      </v-card-text>
    </v-card>   
  
    <JsonDialog />
  </div>
</template>
<script>
import { EventBus } from "@/event-bus"
import Bws from "@/plugins/BackofficeWebsocket.js"
import JsonDialog from "@/components/JsonDialog"

export default {
  name: "provider-tokens",
  props: ["providerId"],
  components: {
    JsonDialog
  },
  data() {
    return {
      ocpiTokens: [],

      tableLoading: false,
      tablePagination: {
        itemsPerPageOptions: [25, 50, 100, 250],
      },
      tableOptions: {
        itemsPerPage: 25,
        page: 1,
      },

      selectedUid: null,
      selectedType: null,
      selectedValid: null,
      selectedDateFrom: null,
      showDateFromPicker: false,
      selectedDateTo: null,
      showDateToPicker: false,

      checkTokens: {
        loading: false,
        selectedDateFrom: new Date().toISOString().substr(0, 10),
        showDateFromPicker: false,
        selectedDateTo: null,
        showDateToPicker: false,
        selectedOffset: null,
        showReport: false,
        tokenCollectionReport: null,
        selectedLimit: 10,
        maxPages: null,
        countryCode: null,
        partyId: null
      }
    };
  },
  watch: {
    selectedDateFrom: function (newValue) {
      if(!newValue) { this.selectedDateFrom = undefined }
    },
    selectedDateTo: function (newValue) {
      if(!newValue) { this.selectedDateTo = undefined }
    },
    //Necessary to update the filters when the user uses the browser back/forward button also to update filters when the user refresh the page
    '$route.query':{ 
        handler(params) {
            this.routeFilters(params)
        },
        immediate: true
    },
  },
  mounted() {
    this.getLastTokenCollectionReport()

    // Nos suscribimos a Redis para nuevos reportes
    this.reportsChannel = Bws.getSubscription(`token-import:token_collection:provider-${this.providerId}`)
    
    this.reportsChannel.on('message', (data) => {
      this.checkTokens.tokenCollectionReport = data
      this.checkTokens.loading = false
      this.checkTokens.showReport = true
      
      if (data.errorMessage) {
        return this.showError(data.errorMessage)
      }
      
      this.showSuccess('A new report of a token import process has been received.')
      this.getOcpiTokens()
    })
  },
  methods: {
    updateTableOptions(args){
      // This check is added to not load table automatically when starting component
      if ( this.tableOptions.itemsPerPage == args.itemsPerPage && this.tableOptions.page == args.page ) { return }
      this.tableOptions = args
      this.getOcpiTokens()
    },
    getOcpiTokens() {
      this.tableLoading = true
      
      let queryParams = ''
      queryParams += `offset=${(this.tableOptions.itemsPerPage * this.tableOptions.page) - this.tableOptions.itemsPerPage}`
      if (this.tableOptions.itemsPerPage > 0) { queryParams += `&limit=${this.tableOptions.itemsPerPage}` }
      if (this.selectedDateFrom) {
            queryParams += `&date_from=${new Date(this.selectedDateFrom).toISOString()}`
            this.urlPush('dateFrom', this.selectedDateFrom)
        }
      if (this.selectedDateTo) { 
            queryParams += `&date_to=${new Date(this.selectedDateTo).toISOString()}`
            this.urlPush('dateTo', this.selectedDateTo)
        }
      if (this.selectedUid) { 
            queryParams += `&uid=${this.selectedUid}`
            this.urlPush('uid', this.selectedUid)
        } else { this.urlPush('uid', null)}
      if (this.selectedType) { 
            queryParams += `&type=${this.selectedType}`
            this.urlPush('type', this.selectedType)
        } else { this.urlPush('type', null)}
      if (this.selectedValid !== null ) { queryParams += `&valid=${this.selectedValid}`}

      this.$api
          .get(`provider/${this.providerId}/paginated-ocpi-tokens?${queryParams}`)
          .then((resp) => this.ocpiTokens = resp.data)
          .catch(this.showError)
          .then(_ => { this.tableLoading = false })
    },
    getLastTokenCollectionReport(){
      this.$api.get(`/provider/${this.providerId}/reports/token_collection`)
        .then(res => this.checkTokens.tokenCollectionReport = res.data)
        .catch(this.showError)
    },
    updateOcpiTokens(date_from, date_to, offset, limit, maxPages, countryCode, partyId) {
      const queryParams = {
        provider_id: this.providerId,
        offset: offset ? offset : 0,
        limit: limit && limit > 0 ? limit : 10
      }

      if (date_from) { queryParams.date_from = date_from }
      if (date_to) { queryParams.date_to= date_to }
      if (maxPages) { queryParams.maxPages = maxPages }
      if (countryCode) { queryParams.countryCode = countryCode }
      if (partyId) { queryParams.partyId= partyId }

      this.checkTokens.loading = true
      this.reportsChannel.emit('import', queryParams)
    },
    showReportDialog(){
      EventBus.$emit(
          "dialog-json:open",
          { title: 'Token collection report', json: this.checkTokens.tokenCollectionReport }
      )
    },
    onFilter(){
        if(this.tableLoading) return
        this.getOcpiTokens()
    },
  },
};
</script>