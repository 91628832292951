<template>
    <div class="d-inline text-no-wrap" v-if="provider">
        <v-tooltip bottom v-if="role">
            <template v-slot:activator="{ on, attrs }">
                <v-chip :small="type != 'title'" v-bind="attrs" v-on="on" outlined :class="role === 'cpo' ? 'secondary' : 'accent'"> 
                    <template v-slot:default>
                        <ProviderNameContent :provider="provider" :extra="extra" :type="type" />
                    </template>
                </v-chip>
            </template>
            <span v-if="role" class="text-uppercase">
                {{ role }}
            </span>
        </v-tooltip>

        <template v-else>
            <v-chip :small="type != 'title'" outlined>
                <ProviderNameContent :provider="provider" :extra="extra" :type="type" />
            </v-chip>
        </template>
    </div>
</template>
  
<script>
    import ProviderNameContent from "@/components/provider/ProviderNameContent.vue";
    export default {
    name: "providerName",
    props: ["provider", "role", "extra", "type"],
    components: {
        ProviderNameContent,
    }
  }
</script>