<template>
    <v-container fluid>
        <AppBar icon="mdi-map-marker" v-if="session" :breadcrumbs="[
            { text: providerName, to: `/provider/${providerId}` },
            { text: 'Sessions', to: `/provider/${providerId}/cpo/sessions` },
            { text: sessionName, icon: 'mdi-sine-wave' },
        ]" />

        <v-card :loading="sessionLoading" outlined v-if="session">
            <v-card-title>
                <span class="ml-4 mr-12">Session {{ session.id }}</span>

                <!-- Sender -->
                <v-chip v-if="session.sender_provider_id === 1" color="secondary" class="text-capitalize"><strong>{{
                        `${session.senderProvider.user.company.name}`
                }}</strong></v-chip>
                <strong v-else>
                    <ProviderName
                        :provider="session.senderProvider"
                        role="cpo"
                        type="title"
                    />
                </strong>
                <!-- Arrow -->
                <v-icon color="secondary">mdi-arrow-right</v-icon>
                <!-- Receiver -->
                <v-chip v-if="session.receiver_provider_id === 1" color="accent" class="text-capitalize">{{
                        `${session.receiverProvider.user.company.name}`
                }}</v-chip>
                <strong v-else>
                    <ProviderName
                        :provider="session.receiverProvider"
                        role="emsp"
                        type="title"
                    />
                </strong>
                <v-spacer />
                <v-btn text outlined @click="showJSON('Session', session)">
                    <v-icon left>mdi-code-json</v-icon>JSON
                </v-btn>
            </v-card-title>

            <v-card-text class="pa-0 mt-2">

                <!-- Basic info -->
                <v-row>
                    <v-col cols="12" md="6">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>{{ session.ocpi_id }}</v-list-item-title>
                                <v-list-item-subtitle>OCPI Id.</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>

                    <v-col cols="12" md="3">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <ProviderName
                                        :provider="session.senderProvider"
                                        type="title"
                                        role="cpo"
                                    />
                                </v-list-item-title>
                                <v-list-item-subtitle>Sender</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <ProviderName
                                        :provider="session.receiverProvider"
                                        type="title"
                                        role="emsp"
                                    />
                                </v-list-item-title>
                                <v-list-item-subtitle>Receiver</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                </v-row>
                <v-divider />

                <!-- Charge parameters -->
                <v-row>
                    <v-col cols="12" md="3">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>{{ session.kwh }} kWh</v-list-item-title>
                                <v-list-item-subtitle>Energy</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title v-if="session.total_cost">{{ session.total_cost.excl_vat }} {{ session.currency }}
                                </v-list-item-title>
                                <v-list-item-subtitle>Total cost (Without taxes)</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>

                    <v-col cols="12" md="3">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>{{ session.start_date_time | dateFullFormat }}</v-list-item-title>
                                <v-list-item-subtitle>Started at</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>{{ session.end_date_time | dateFullFormat }}</v-list-item-title>
                                <v-list-item-subtitle>Stopped at</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                </v-row>
                <v-divider />

                <!-- Dates -->
                <v-row>
                    <v-col cols="12" md="3">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>{{ session.created_at | adonisDateFullFormat }}</v-list-item-title>
                                <v-list-item-subtitle>Created at</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>{{ session.updated_at | adonisDateFullFormat }}</v-list-item-title>
                                <v-list-item-subtitle>Updated at</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>{{ session.last_updated | dateFullFormat }}</v-list-item-title>
                                <v-list-item-subtitle>Last updated</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>{{ session.sent_meter_success_at | dateFullFormat }}</v-list-item-title>
                                <v-list-item-subtitle>Success Meter sent at</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                </v-row>
                <v-divider />

                <!-- -->
                <v-row>
                    <v-col cols="12" md="3">
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>{{ session.auth_method }}</v-list-item-title>
                                <v-list-item-subtitle>Auth method</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                    <v-col cols="12" md="3" class="d-flex align-center">
                        <v-btn v-if="session.sender_provider_id == 1 && session.http_request"  
                            text 
                            @click="showJSON('Http Request', { http_request: session.http_request, http_response: session.http_request }, 3)" 
                        >
                            <v-icon left>mdi-code-json</v-icon>Http Request
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="3" class="d-flex align-center">
                        <v-btn text @click="showJSON('Charging periods', session.charging_periods, 2)">
                            <v-icon left>mdi-code-json</v-icon> Charging periods
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="3" class="d-flex align-center">
                        <v-btn text @click="showJSON('CDR Token', session.cdr_token, 2)">
                            <v-icon left>mdi-code-json</v-icon> CDR Token
                        </v-btn>
                    </v-col>
                </v-row>

            </v-card-text>
        </v-card>

        <v-row class="mt-3" v-if="session && session.dtm_session_id"> 
            <v-col class="d-flex mr-3">
                <v-spacer />
                <DTMSessionButton :dtmSessionId="session.dtm_session_id" />
            </v-col>
        </v-row>

        <v-row v-if="session" class="mt-3" >
            <!-- Location -->
            <v-col cols="12" sm="3">
                <v-card outlined height="100%">
                    <v-card-title>
                        <v-icon left color="accent">mdi-map-marker</v-icon>
                        <span class="title">Location</span>
                    </v-card-title>
                    <v-card-text class="pa-0">
                        <v-list class="ultra-dense pa-0">
                            <v-list-item two-line class="mt-3 mb-2">
                                <v-list-item-content>
                                    <LocationName
                                        class="text-center"
                                        v-if="session.location"
                                        :location="{ id: session.location.id, name: session.location.name }"
                                    />
                                </v-list-item-content>
                            </v-list-item>
                            <v-row>
                                <v-col>
                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ session.location.city || '-' }}</v-list-item-title>
                                            <v-list-item-subtitle>City</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col>
                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <ProviderName
                                                    :provider="session.senderProvider"
                                                    type="title"
                                                    role="cpo"
                                                />
                                            </v-list-item-title>
                                            <v-list-item-subtitle>Owner</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-list>

                        <v-divider></v-divider>

                        <v-row>
                            <v-col>
                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ session.location.id }}</v-list-item-title>
                                        <v-list-item-subtitle>Id</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col>
                                <v-list-item >
                                    <v-list-item-content>
                                        <v-list-item-title>{{ session.location.backend_id }}</v-list-item-title>
                                        <v-list-item-subtitle>Core Id</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item> 
                            </v-col>
                        </v-row>

                    </v-card-text>
                </v-card>
            </v-col>

            <!-- Chargepoint -->
            <v-col cols="12" sm="3" >
                <v-card outlined height="100%">
                    <v-card-title>
                        <v-icon left color="accent">mdi-ev-station</v-icon>
                        <span class="title">Chargepoint</span>
                    </v-card-title>
                    <v-card-text class="pa-0">

                        <v-list class="ultra-dense pa-0">
                            <v-list-item two-line class="mt-3 mb-2">
                                <v-list-item-content>
                                    <ChargepointName
                                        class="text-center"
                                        v-if="session.evse"
                                        :chargepoint="{ id: session.evse.chargepoint_id, name: session.evse.chargepoint.reference_name }"
                                    />
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-title>{{ session.evse.chargepoint.number || '-' }}</v-list-item-title>
                                    <v-list-item-subtitle>Number</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>

                        <v-divider></v-divider>
                        <v-row>
                            <v-col>
                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ session.evse.chargepoint.id }}</v-list-item-title>
                                        <v-list-item-subtitle>Id</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>

                            <v-col>
                                <v-list-item >
                                    <v-list-item-content>
                                        <v-list-item-title>{{ session.evse.chargepoint.backend_id }}</v-list-item-title>
                                        <v-list-item-subtitle>Core Id</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item> 
                            </v-col>
                        </v-row>

                    </v-card-text>
                </v-card>
            </v-col>

            <!-- Evse -->
            <v-col cols="12" sm="3">
                <v-card outlined height="100%">
                    <v-card-title>
                        <v-icon left color="accent">mdi-car-multiple</v-icon>
                        <span class="title">Evse</span>
                    </v-card-title>
                    <v-card-text class="pa-0">

                        <v-list class="ultra-dense pa-0">
                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-title>{{ session.evse.evse_id }}</v-list-item-title>
                                    <v-list-item-subtitle>Evse Id</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-title>{{ session.evse.status }}</v-list-item-title>
                                    <v-list-item-subtitle>Status</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>

                        <v-divider></v-divider>
                        <v-row>
                            <v-col>
                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ session.evse.id }}</v-list-item-title>
                                        <v-list-item-subtitle>Id</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                        </v-row>

                    </v-card-text>
                </v-card>
            </v-col>

            <!-- Connector -->
            <v-col cols="12" sm="3">
                <v-card outlined height="100%">
                    <v-card-title>
                        <v-icon color="accent">mdi-power-plug</v-icon>
                        <span class="title">Connector</span>
                    </v-card-title>
                    <v-card-text class="pa-0">
                        
                        <v-list class="ultra-dense pa-0">
                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-title>{{ session.connector ? session.connector.standard : '-' }}</v-list-item-title>
                                    <v-list-item-subtitle>Standard</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-title>{{ session.connector ? session.connector.power_type : '-' }}</v-list-item-title>
                                    <v-list-item-subtitle>Power Type</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-title>{{ session.connector ? session.connector.max_electric_power : '-' }} W</v-list-item-title>
                                    <v-list-item-subtitle>Max electric power</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>

                        <v-divider></v-divider>

                        <v-row>
                            <v-col>
                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ session.connector ? session.connector.id : '-' }}</v-list-item-title>
                                        <v-list-item-subtitle>Id</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col>
                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ session.connector ? session.connector.number : '-'}}</v-list-item-title>
                                        <v-list-item-subtitle>Number</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col>
                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ session.connector ? session.connector.backend_id : '-' }}</v-list-item-title>
                                        <v-list-item-subtitle>Core Id</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row v-if ="session && (session.commandStart || session.commandStop)">
            <!-- Command Start -->
            <v-col cols="12" md="6">
                <v-card outlined v-if="session && session.commandStart">
                    <v-card-title>Command Start</v-card-title>
                    <v-card-text class="pa-0">
                        <v-list class="ultra-dense pa-0">
                            <v-row>
                                <v-col cols="12" :sm="session.commandStart.sender_provider_id === 1 ? 3 : 4" class="pb-0 pt-6">
                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <v-btn text @click="showJSON('Command Start request', session.commandStart.req_http_received)">
                                                    <v-icon left :color="session.commandStart.req_success ? 'success' : 'error'">
                                                        {{ session.commandStart.req_success ? 'mdi-check-circle' : 'mdi-close-circle' }}
                                                    </v-icon>
                                                    Request
                                                </v-btn>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="12" :sm="session.commandStart.sender_provider_id === 1 ? 3 : 4" class="pb-0 pt-6">
                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <v-btn text @click="showJSON('Command Start Core response', session.commandStart.resp_http_received)">
                                                    <v-icon left :color="session.commandStart.resp_success ? 'success' : 'error'">
                                                        {{ session.commandStart.resp_success ? 'mdi-check-circle' : 'mdi-close-circle' }}
                                                    </v-icon>
                                                    Core Response
                                                </v-btn>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="12" :sm="session.commandStart.sender_provider_id === 1 ? 3 : 4" class="pb-0 pt-6">
                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <v-btn text @click="showJSON('Command Start async result', session.commandStart.res_http_received)">
                                                    <v-icon left :color="session.commandStart.res_success ? 'success' : 'error'">
                                                        {{ session.commandStart.res_success ? 'mdi-check-circle' : 'mdi-close-circle' }}
                                                    </v-icon>
                                                    Async Result
                                                </v-btn>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="12" :sm="session.commandStart.sender_provider_id === 1 ? 3 : 4" class="pb-0 pt-6">
                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <v-btn text @click="showJSON('Http Request', { http_request: session.commandStart.http_request, http_response: session.commandStart.http_response }, 3)">
                                                    <v-icon left>mdi-code-json</v-icon>Http Request
                                                </v-btn>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ session.commandStart.token.uid }}</v-list-item-title>
                                            <v-list-item-subtitle>Token ({{ session.commandStart.token.type }})
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ session.commandStart.token.auth_id }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>Token Auth Id</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ session.commandStart.authorization_reference }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>Authorization reference</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-list>    
                        <v-divider></v-divider>
                        <v-list class="ultra-dense pa-3">
                            <v-row class="pt-2">
                                <v-col cols="12" sm="3">
                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <v-btn :to="`/command/START_SESSION/${session.commandStart.id}`" text>
                                                    <v-icon left color="accent">mdi-console-line</v-icon>
                                                    {{ session.commandStart.id }}
                                                </v-btn></v-list-item-title>
                                            <v-list-item-subtitle>Id</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ session.commandStart.backend_uuid }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>Core UID</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="12" sm="3">
                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ session.commandStart.dtm_session_id }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>DTM Session Id</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>

            <!-- Command Stop -->
            <v-col cols="12" md="6">
                <v-card outlined height="100%" v-if="session && session.commandStop">
                    <v-card-title>Command Stop</v-card-title>
                    <v-card-text class="pa-0">
                        <v-list class="ultra-dense pa-0">
                                <v-row>
                                    <v-col cols="12" :sm="session.commandStop.sender_provider_id === 1 ? 3 : 4" class="pb-0 pt-6">
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <v-btn text @click="showJSON('Command Stop request', session.commandStop.req_http_received)">
                                                        <v-icon left :color="session.commandStop.req_success ? 'success' : 'error'">
                                                            {{ session.commandStop.req_success ? 'mdi-check-circle' : 'mdi-close-circle' }}
                                                        </v-icon>
                                                        Request
                                                    </v-btn>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                    <v-col cols="12" :sm="session.commandStop.sender_provider_id === 1 ? 3 : 4" class="pb-0 pt-6">
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <v-btn text @click="showJSON('Command Stop Core response', session.commandStop.resp_http_received)">
                                                        <v-icon left :color="session.commandStop.resp_success ? 'success' : 'error'">
                                                            {{ session.commandStop.resp_success ? 'mdi-check-circle' : 'mdi-close-circle' }}
                                                        </v-icon>
                                                        Core Response
                                                    </v-btn>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                    <v-col cols="12" :sm="session.commandStop.sender_provider_id === 1 ? 3 : 4" class="pb-0 pt-6">
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <v-btn text @click="showJSON('Command Stop async result', session.commandStop.res_http_received)">
                                                        <v-icon left :color="session.commandStop.res_success ? 'success' : 'error'">
                                                            {{ session.commandStop.res_success ? 'mdi-check-circle' : 'mdi-close-circle' }}
                                                        </v-icon>
                                                        Async Result
                                                    </v-btn>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                    <v-col cols="12" :sm="session.commandStop.sender_provider_id === 1 ? 3 : 4" class="pb-0 pt-6">
                                    <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <v-btn text @click="showJSON('Http Request', { http_request: session.commandStop.http_request, http_response: session.commandStop.http_response }, 3)">
                                                        <v-icon left>mdi-code-json</v-icon>Http Request
                                                    </v-btn>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ session.commandStop.created_at | adonisDateFullFormat }}</v-list-item-title>
                                                <v-list-item-subtitle>Created at</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ session.commandStop.updated_at | adonisDateFullFormat }}</v-list-item-title>
                                                <v-list-item-subtitle>Updated at</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                </v-row>
                        </v-list>    
                        <v-divider></v-divider>
                        <v-list class="ultra-dense pa-3">
                            <v-row class="pt-2">
                                <v-col cols="12" sm="12">
                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <v-btn :to="`/command/STOP_SESSION/${session.commandStop.id}`" text>
                                                    <v-icon left color="accent">mdi-console-line</v-icon>
                                                    {{ session.commandStop.id }}
                                                </v-btn></v-list-item-title>
                                            <v-list-item-subtitle>Id</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
 
        <v-row v-if="session && session.cdr">
            <!-- CDR -->
            <v-col cols="12" sm="12" md="12">
                <v-card v-if="session && session.cdr" outlined >
                    <v-card-title>
                        <v-icon left color="accent"> mdi-credit-card-multiple-outline </v-icon>
                        <span class="title">CDR</span>
                    </v-card-title>

                    <v-card-text class="pa-0">

                        <v-list class="ultra-dense pa-3">
                            <v-row>
                                <v-col cols="12" sm="3" v-if="session.cdr.sender_provider_id === 1">
                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title> 
                                                <span class="sent-span">           
                                                    <v-icon left style="font-size:18px" :color="!session.cdr.sent_to_provider && session.cdr.sender_provider_id === 1 ? 'error' : 'success'" @click="checkNextVal(session.cdr)">
                                                        {{ !session.cdr.sent_to_provider && session.cdr.sender_provider_id === 1 ? 'mdi-close-circle' : 'mdi-check-circle' }}
                                                    </v-icon>
                                                    SENT TO PROVIDER
                                                </span>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>

                                <v-col cols="12" sm="3">
                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title><span v-if="session.cdr.total_cost">{{ session.cdr.total_cost.excl_vat }}
                                                    {{ session.cdr.currency }}</span>
                                                <span v-else>-</span>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>Total cost</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>

                                <v-col cols="12" sm="3">
                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ session.cdr.total_energy }}</v-list-item-title>
                                            <v-list-item-subtitle>kWh</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>

                                <v-col cols="12" sm="3">
                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <v-btn v-if="session.cdr.tariff_id" text :to="`/tariffs/${session.cdr.tariff_id}`">
                                                    <v-icon left color="accent">mdi-tag-text-outline</v-icon>{{ session.cdr.tariff_id }}
                                                </v-btn>
                                                <template v-else>-</template>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>Applied tariff</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ session.cdr.total_time | hoursToTimeTextFormat }}</v-list-item-title>
                                            <v-list-item-subtitle>Time</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ session.cdr.start_date_time | dateFullFormat }}</v-list-item-title>
                                            <v-list-item-subtitle>Start at</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ session.cdr.end_date_time | dateFullFormat }}</v-list-item-title>
                                            <v-list-item-subtitle>End at</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ session.cdr.updated_at | adonisDateFullFormat }}</v-list-item-title>
                                            <v-list-item-subtitle>Updated at</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>

                            </v-row>
                        </v-list>    
                        
                        <v-divider></v-divider>
                
                        <v-list class="ultra-dense pa-3">
                            <v-row class="pt-2">

                                <v-col cols="12" md="3">
                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ session.cdr.id }}</v-list-item-title>
                                            <v-list-item-subtitle>Id</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ session.cdr.ocpi_id }}</v-list-item-title>
                                            <v-list-item-subtitle>OCPI Id</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>

                                <v-col cols="12" :md="session.cdr.sender_provider_id === 1 ? 2 : 3" class="d-flex align-center" v-if="session.cdr.sender_provider_id === 1">
                                    <v-btn text @click="showJSON('Http Request', { http_request: session.cdr.http_request, http_response: session.cdr.http_response }, 3)">
                                        <v-icon left>mdi-code-json</v-icon>Http Request
                                    </v-btn>
                                </v-col>

                                <v-col cols="12" :md="session.cdr.sender_provider_id === 1 ? 2 : 3" class="d-flex align-center" v-if="session.cdr.location_snapshot">
                                    <v-btn text @click="showJSON('Location snapshot', session.cdr.location_snapshot)">
                                        <v-icon left>mdi-code-json</v-icon>Location snapshot
                                    </v-btn>
                                </v-col>

                                <v-col cols="12" :md="session.cdr.sender_provider_id === 1 ? 2 : 3" class="d-flex align-center" v-if="session.cdr.tariff_snapshot">
                                    <v-btn text @click="showJSON('Tariff snapshot', session.cdr.tariff_snapshot)">
                                        <v-icon left>mdi-code-json</v-icon>Tariff snapshot
                                    </v-btn>
                                </v-col>

                            </v-row>
                        </v-list>

                    </v-card-text>
                </v-card>
            </v-col>
            
            <!-- Tariff -->
            <v-col cols="12" sm="12" md="12" v-if="session.cdr.tariff">
                <v-card outlined height="100%">
                    <v-card-title>
                        <v-icon left color="accent"> mdi-tag-text-outline </v-icon>
                        <span class="title">Tariff</span>
                        <v-btn text class="ml-2 px-0" :to="`/tariffs/${session.cdr.tariff.id}`"><v-icon small>mdi-arrow-top-right</v-icon></v-btn>
                    </v-card-title>
    
                    <v-card-text>
    
                        <v-row>
                            <v-col v-if="session.cdr.tariff.deleted_at" cols="12" md="12" class="mt-4 ml-4 pb-0">
                                <v-chip outlined color="error">REMOVED</v-chip>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ session.cdr.tariff.id }}</v-list-item-title>
                                        <v-list-item-subtitle>Id</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <ProviderName
                                                :provider="session.cdr.tariff"
                                                type="title"
                                            />
                                        </v-list-item-title>
                                        <v-list-item-subtitle>Provider</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ session.cdr.tariff.currency }}</v-list-item-title>
                                        <v-list-item-subtitle>Currency</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ session.cdr.tariff.ocpi_id }}</v-list-item-title>
                                        <v-list-item-subtitle>OCPI Id.</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" md="6" class="py-0">
                                <span class="text-caption ml-3">Elements</span>
                                <template v-if="session.cdr.tariff.elements && session.cdr.tariff.elements.a && session.cdr.tariff.elements.a.length > 0">
                                    <v-list shaped class="pa-0 tariffs_list custom-scollbar">
                                        <v-list-item v-for="(element, index) in session.cdr.tariff.elements.a" :key="index">
                                            <v-list-item-content>
                                                <v-row class="ml-0">
                                                    <span class="mt-2">{{ index + 1 }} - </span>
                                                    <PriceComponentChip v-for="(price_component, index) in element.price_components" 
                                                        :key="index" class="ml-2" 
                                                        :priceComponent="price_component"
                                                        :currency="session.cdr.tariff.currency"
                                                    />
                                                    <div v-for="(value, key, index) in element.restrictions" :key="`res-${index}`" style="width=auto;" class="pt-1">
                                                        <span class="text-caption ml-3">{{ key }}: </span><span>{{ value }}</span>
                                                    </div>
                                                </v-row>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </template>
                                <v-row v-else class="ml-4 pt-2 pb-4">
                                    <span>-</span>
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="6" class="py-0">
                                <span class="text-caption ml-3">Description</span>
                                <template v-if="session.cdr.tariff.tariff_alt_text && session.cdr.tariff.tariff_alt_text.a && session.cdr.tariff.tariff_alt_text.a.length > 0">
                                    <v-list shaped class="pa-0 tariffs_list custom-scollbar">
                                        <v-list-item v-for="(tariff_alt_text, index) in session.cdr.tariff.tariff_alt_text.a" :key="index">
                                            <v-list-item-content>
                                                <v-row class="ml-0">
                                                    <span class="mt-2">{{ index + 1 }} - </span>
                                                    <v-chip class="ml-3" outlined color="accent">{{ tariff_alt_text.language }}</v-chip>
                                                    <span class="ml-3 mt-2">{{ tariff_alt_text.text }}</span>
                                                </v-row>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </template>
                                <v-row v-else class="ml-4 pt-2 pb-4">
                                    <span>-</span>
                                </v-row>
                            </v-col>
                        </v-row>
    
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <!-- Stop Charging -->
            <v-col cols="12" sm="6" md="4" v-if="session && session.senderProvider.id != 1 && session.status != 'COMPLETED'">
                <v-card outlined height="100%">

                    <v-card-title>
                        <v-icon small left color="accent">mdi-wrench</v-icon>
                        Stop Charging Session
                    </v-card-title>

                    <v-card-text class="pa-4">
                        <p class="mb-0">POST /commands/STOP_SESSION</p>
                    </v-card-text>

                    <v-card-actions>
                        <v-btn @click="stopSession" text block :loading="loadingStopSession">
                            <v-icon left color="accent">mdi-stop</v-icon>
                            STOP_SESSION
                        </v-btn>
                    </v-card-actions>

                </v-card>
            </v-col>

            <!-- Generate CDR -->
            <v-col v-if="session && session.senderProvider.id == 1 && !session.cdr" cols="12" sm="6" md="4">
                <v-card outlined height="100%">

                    <v-card-title>
                        <v-icon small left color="accent">mdi-wrench</v-icon>
                        Generate Session CDR
                        <v-spacer></v-spacer>
                        <v-chip small color="warning" v-if="session.kwh <= 0.2">Caution! Less or equal than 0.2 kWh</v-chip>
                    </v-card-title>

                    <v-card-text class="pa-4">
                        <p class="mb-0">POST /generate-cdr</p>
                    </v-card-text>

                    <v-card-actions>
                        <v-btn @click="() => generateCdr(session.backend_uuid)" text block :loading="loadingGenerateCDR">
                            <v-icon left color="accent">mdi-credit-card-multiple-outline</v-icon>
                            GENERATE CDR
                        </v-btn>
                    </v-card-actions>

                </v-card>
            </v-col>

            <!-- Send Session to Provider -->
            <v-col v-if="session && session.sender_provider_id === 1" cols="12" sm="6" md="4">
                <v-card outlined height="100%">

                    <v-card-title>
                        <v-icon small left color="accent">mdi-wrench</v-icon>
                        Send Session to Provider
                    </v-card-title>

                    <v-card-text class="pa-4">
                        <p class="ma-0">Send session with current data to provider.</p>
                    </v-card-text>

                    <v-card-actions>
                        <v-btn @click="() => sendSessionToProvider()" text block>
                            <v-icon left color="accent">mdi-send</v-icon>
                            Send Session
                        </v-btn>
                    </v-card-actions>

                </v-card>
            </v-col>

            <!-- Send CDR to Provider -->
            <v-col v-if="session && session.cdr && !session.cdr.sent_to_provider && session.cdr.sender_provider_id === 1" cols="12" sm="6" md="4">
                <v-card outlined height="100%">

                    <v-card-title>
                        <v-icon small left color="accent">mdi-wrench</v-icon>
                        Send CDR to Provider
                    </v-card-title>

                    <v-card-text class="pa-4">
                        <p class="mb-0">POST /send-cdr</p>
                    </v-card-text>

                    <v-card-actions>
                        <v-btn @click="() => sendCdrToProvider(session.cdr)" text block >
                            <v-icon left color="accent">mdi-send</v-icon>
                            SEND CDR
                        </v-btn>
                    </v-card-actions>

                </v-card>
            </v-col>
        </v-row>

        <JsonDialog/>

    </v-container>
</template>

<script>
import { EventBus } from "@/event-bus"
import AppBar from "@/components/AppBar"
import JsonDialog from "@/components/JsonDialog"
import ProviderName from "@/components/provider/ProviderName.vue"
import LocationName from "@/components/location/LocationName.vue"
import ChargepointName from "@/components/chargepoint/ChargepointName.vue"
import DTMSessionButton from "@/components/session/DTMSessionButton.vue"
import PriceComponentChip from "@/components/tariff/PriceComponentChip"

export default {
    components: { AppBar, JsonDialog, ProviderName, LocationName, ChargepointName, DTMSessionButton, PriceComponentChip },
    data() {
        return {
            provider: null,
            session: null,
            sessionLoading: false,
            cdr: null,
            loadingStopSession: false,
            loadingGenerateCDR: false,
            jsonDialogShow: false,
            jsonDialogTitle: '',
            jsonDialogData: null,
            jsonDialogExpand: 1
        }
    },
    computed: {
        providerName: function () {
            return this.session
                ? `${this.session.senderProvider.country_code}-${this.session.senderProvider.party_id} (${this.session.senderProvider.role})`
                : "-"
        },
        providerId: function () {
            return this.session ? this.session.senderProvider.id : 0
        },
        sessionName: function () {
            return this.session ? this.session.ocpi_id?.substr(-12, 12) : "-"
        },
    },
    async mounted() {
        this.load()
    },
    watch: {
        '$route' () {
            this.load()
        }
    },
    methods: {
        async load() {
            const id = this.$router.currentRoute.params.id

            this.sessionLoading = true

            this.session = await this.$api.get(`session/${id}`)
                .then(resp => resp.data)
                .catch(this.showError)
                     

            this.sessionLoading = false

        },
        async stopSession() {
            const vm = this
            this.loadingStopSession = true

            await this.$api
                .post(`session/${this.session.id}/send-stop`)
                .catch(this.showError)
                .then((_) => {
                    vm.load()
                })

            this.loadingStopSession = false
        },
        async generateCdr(backend_uuid){
            this.loadingGenerateCDR = true
            if (!backend_uuid) {
            this.showError('Session has no backend_uuid')
            return 
            }

            await this.$api.post(`generate-cdr/${backend_uuid}`)
            .then(() => this.showCustomAlert('success', 'Process has been executed.', { to:`/cdr-processes?search=${backend_uuid}`, text:'Check CDR process', icon:'mdi-arrow-top-right' }))
            .catch(this.showError)
            .then(() => {
                this.loadingGenerateCDR = false
                this.load()
            })
        },
        showJSON(title, data, expand = 1) {
            EventBus.$emit(
                "dialog-json:open",
                { title, json: data, expand, maxWidth: 600 }
            )
        },
        sendSessionToProvider() {
            this.$api.post(`session/${this.session.id}/send`)
                .then(() => this.showSuccess('Session has been sent successfully'))
                .catch(this.showError)
        },
        sendCdrToProvider(cdr) {
            this.$api
            .post(`send-cdr/${cdr.id}`)
            .then((resp) => { 

            if (resp.data.success) {
                cdr.sent_to_provider = true
                cdr.sender_provider_id = 1
                this.showSuccess('CDR has been sent successfully')
            } else {
                this.showError('CDR has not been sent correctly')
            }
            })
            .catch(this.showError)
        },
        checkNextVal(item){

            const confirmed = window.confirm('Are you sure you want to change the value of the field?')
            if(!confirmed){ return }

            const newValue = !item.sent_to_provider

            this.$api
            .patch(`cdr/${item.id}`, { sent_to_provider: newValue })
            .then(() => {this.session.cdr.sent_to_provider = newValue})
            .catch(this.showError)
        }
    },
}
</script>

<style scoped>
    >>> .v-btn:not(.v-btn--round).v-size--small {
        height: 0px;
        min-width: 50px;
        padding: 0 0px;
    }

    >>> .sent-span {
        font-weight: 500;
        font-size: 14px;
        display: contents;
    }

    >>> .v-list-item__title {
        height: 36px;
        line-height: 36px;
    }

    >>> .v-card__title {
        padding-bottom: 0;
    }

    .ultra-dense .v-list-item {
        min-height: 0;
    }
    
    .ultra-dense .v-list-item__content {
        padding-top: 6px;
        padding-bottom: 6px;
    }
    .tariffs_list {
        max-height: 145px;
        display: block;
        overflow-y: auto;
    }
</style>