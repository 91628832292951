<template>
    <v-container fluid>
        <AppBar title="Connections" icon="mdi-vector-polyline" />

        <!-- Table filters -->
        <v-row class="filer-row">
            <v-col cols="12" md="2">
                <v-text-field
                    v-model="selectedSearch"
                    class="search-field"
                    placeholder="Search"
                    color="accent"
                    autofocus
                    outlined
                    hide-details
                    @keyup.enter="onFilter"
                >
                    <template v-slot:append>
                        <v-btn
                            color="accent" 
                            text
                            @click.stop="onFilter"
                        >
                            <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                    </template>
                </v-text-field>
            </v-col>

            <v-btn 
                :loading="tableLoading"
                @click="onFilter"
                color="accent"
                text
            >
                SEARCH
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
                class="mr-2"
                to="/connection/create"
                color="accent"
                outlined
            >
                <v-icon left>mdi-plus</v-icon>Create
            </v-btn>
        </v-row>

        <!-- Data table -->
        <v-data-table
            :headers="[
                { value: 'linkto', sortable: false, cellClass: 'td-linkto' },
                { text: 'Id.', value: 'id' },
                { text: 'Name', value: 'name' },
                { text: 'Type', value: 'type', align: 'center' },
                { text: 'Status', value: 'status', align: 'center' },
                { text: 'URL', value: 'ocpi_url_versions' },
                { text: 'OCPI Ver.', value: 'ocpi_version' },
                { text: 'Updated', value: 'updated_at' }
            ]"
            :items="connections"
            :loading="tableLoading"
            :search="tableSearch"
            :sort-by="['id']"
            :sort-desc="[false]"
            disable-pagination
            hide-default-footer
            dense
            class="accent-table rounded-table"
        >
            <template v-slot:item.linkto="{ item }">
                <v-btn plain small :to="`/connection/${item.id}`">
                    <v-icon small>mdi-arrow-top-right</v-icon>
                </v-btn>
            </template>
            <template v-slot:item.type="{ item }">
                <v-chip
                    v-if="item.type"
                    color="accent"
                    outlined
                    small
                >
                    {{ item.type == 'provider' ? 'DIRECT' : item.type.toUpperCase() }}
                </v-chip>
            </template>
            <template v-slot:item.status="{ item }">
                <StatusChip v-if="item.status" :status="item.status" />
            </template>
            <template v-slot:item.ocpi_version="{ item }">
                <v-chip
                    v-if="item.ocpi_version"
                    color="accent"
                    outlined
                    small
                >
                    {{ item.ocpi_version }}
                </v-chip>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import { EventBus } from "@/event-bus";
import api from "@/axios.instance";
import AppBar from "@/components/AppBar";
import StatusChip from "@/components/connection/StatusChip";

export default {
    name: "connections",
    components: { 
        AppBar,
        StatusChip
    },
    data() {
        return {
            connections: [],
            tableLoading: false,
            selectedSearch: null,
            tableSearch: null,
        };
    },
    mounted() {
        EventBus.$on("dialog-provider:close", (data) => {
            this.loadList();
        });
    },
    watch: {
        //Necessary to update the filters when the user uses the browser back/forward button also to update filters when the user refresh the page
        '$route.query':{ 
            handler(params) {
                this.routeFilters(params)
                this.onFilter()
            },
            immediate: true
        },
    },
    methods: {
        loadList() {
            this.tableLoading = true
            api.get("/connections")
                .then((res) => {
                    this.connections = res.data.map((connection) => {
                        return {
                            name: connection.company ? connection.company.name : connection.username,
                            ...connection,
                        }
                    })
                })
                .catch((e) => this.showError)
                .finally(() => this.tableLoading = false)
        },
        showRoles(roles) {
            return roles
                ? roles
                      .map((ob) => {
                          return ob.role;
                      })
                      .join(", ")
                : "";
        },
        onFilter(){
            if(this.tableLoading) return
            this.tableSearch = this.selectedSearch
            this.urlPush('search', this.selectedSearch)

            this.loadList()
        },
    },
};
</script>