<template>

  <v-container fluid>

    <AppBar title="Charge requests" icon="mdi-application-import" />

    <!-- Table filters -->
    <v-row class="filer-row">
      <v-col cols="12" md="2">
          <v-text-field
            class="search-field"
            v-model="selectedSearch"
            placeholder="Search"
            append-icon="mdi-magnify"
            color="accent"
            hide-details
            outlined
            autofocus
            @keyup.enter="onFilter"
          >
            <template v-slot:append>
              <v-btn
                  color="accent" 
                  text
                  @click.stop="onFilter"
              >
                  <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
          </v-text-field>
      </v-col>     

      <v-col cols="12" md="2">
        <v-autocomplete
          class="filter-field"
          v-model="selectedProvider"
          :items="providers"
          label="Provider"
          color="accent"
          outlined
          clearable
          hide-details
        >
          <template v-slot:item="data">
              <ProviderSelectorTemplate :provider="data.item" />
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="12" md="2">
        <v-menu
            ref="menu"
            class="filter-field"
            v-model="showDatePicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"    
        >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  v-on="on"
                  class="filter-field"
                  v-model="selectedDateFrom"
                  label="Date from"
                  prepend-inner-icon="mdi-calendar"
                  color="accent"
                  outlined
                  readonly
                  hide-details
                ></v-text-field>
            </template>
            <v-date-picker
                v-model="selectedDateFrom"
                @input="showDatePicker = false"
                no-title
            ></v-date-picker>
        </v-menu>
      </v-col>
      <v-btn 
        :loading="loading"
        @click="onFilter"
        color="accent"
        text
      >
        SEARCH
      </v-btn>
    </v-row>
    
    <!-- Data table -->
    <v-data-table
        class="accent-table rounded-table"
        :items="chargeRequests"
        :headers="[
            { text: 'Provider', value: 'provider' },
            { text: 'Type', value: 'type' },
            { text: 'Chargepoint', value: 'chargepoint'},
            { text: 'Command id', value: 'command_id' },
            { text: 'Step', value: 'step' },
            { text: 'Success', value: 'success' },
            { text: 'Created at', value: 'timestamp' },
        ]"
        dense
        :search="tableSearch"
        :custom-filter="dataTableDeepSearch"
        :loading="loading"     
        item-key="id"
        sort-by="timestamp"
        sort-desc
        show-expand
    >
      <template v-slot:item.provider="{ item }">
            <ProviderName
                v-if="item.user.provider_name"
                :provider="{ id: item.user.provider_id, country_code: item.user.provider_name.split('-')[0], party_id: item.user.provider_name.split('-')[1].split(' as ')[0]  }"
                role="emsp"
            />
      </template>
      <template v-slot:item.type="{ item }">
        <span class="text-overline">{{ item.type }}</span>
        <span v-if="item.command_type" class="text-overline"> {{ item.command_type.split('_')[0] }}</span>
      </template>
      <template v-slot:item.chargepoint="{ item }">
        <ChargepointName
            v-if="item.chargepoint && item.chargepoint.name"
            :chargepoint="{ id: item.chargepoint.id, name: item.chargepoint.name }"
            type="table"
        />
      </template>
      <template v-slot:item.success="{ item }">
        <template v-if="item.success !== undefined && item.success !== null">
          <v-icon v-if="item.success" color="success">mdi-check-circle</v-icon>
          <v-icon v-else color="error">mdi-close-circle</v-icon>
        </template>
        <v-icon v-else color="warning">mdi-help-circle</v-icon>
      </template>
      <template v-slot:item.timestamp="{ item }">
            {{ item.timestamp | dateFullFormat }}
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
            <template v-if="item.type == 'rta'">
              <v-col cols="12" md="12" class="d-flex flex-column">
                  <span class="pl-2 py-2 text-overline">Request</span>
                  <json-viewer
                      class="pa-0"
                      :value="(({ id, ...args })=>args)(item)"
                      :copyable="{copyText: 'Copy', copiedText: 'Copied!', timeout: 2000}"
                      :expand-depth="1"
                      >
                  </json-viewer>
              </v-col>
            </template>
            <template v-if="item.type == 'command'">
              <v-row class="pb-3">
                <v-col cols="5" md="5" class="d-flex flex-column">
                    <span class="pl-2 py-3 text-overline">Sync response</span>
                    <json-viewer
                        class="pa-0"
                        :value="item.syncCommand"
                        :copyable="{copyText: 'Copy', copiedText: 'Copied!', timeout: 2000}"
                        :expand-depth="1"
                        >
                    </json-viewer>
                </v-col>
                <v-col cols="5" md="5" class="d-flex flex-column">
                  <v-row class="pt-3">
                    <v-divider vertical />
                    <v-col class="pt-0 d-flex flex-column">
                      <span class="pl-2 py-2 text-overline">Async response</span>
                      <json-viewer v-if="item.asyncCommand"
                          class="pa-0"
                          :value="item.asyncCommand"
                          :copyable="{copyText: 'Copy', copiedText: 'Copied!', timeout: 2000}"
                          :expand-depth="1"
                          >
                      </json-viewer>
                      <span v-else class="pl-2 json-text-orange">null</span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </template>
        </td>
      </template>
    </v-data-table>

  </v-container>
  
</template>

<script>
import AppBar from "@/components/AppBar"
import ProviderSelectorTemplate from "@/components/provider/ProviderSelectorTemplate"
import ProviderName from "@/components/provider/ProviderName"
import ChargepointName from "@/components/chargepoint/ChargepointName"

export default {
    components: {
        AppBar,
        ProviderSelectorTemplate,
        ProviderName,
        ChargepointName,
    },
    data() {
      return {
        chargeRequests: [],
        selectedSearch: null,
        tableSearch: null,
        selectedProvider: null,
        selectedDateFrom: new Date().toISOString().substr(0, 10),
        showDatePicker: false,
        providers: [],
        loading: false,
      }
    },
    watch: {
        //Necessary to update the filters when the user uses the browser back/forward button also to update filters when the user refresh the page
        '$route.query':{ 
            handler(params) {
                this.routeFilters(params)
                this.onFilter()
            },
            immediate: true
        },
    },
    async mounted(){
      this.$api.get("/providers")
        .then(res => {
            this.providers = res.data
                .filter(p => p.role.includes('EMSP'))
                .map(p => { 
                    return { 
                        ...p, 
                        text: p.role ? `${p.country_code}-${p.party_id} ${p.company?.name || p.user.company?.name || p.user.username} (${p.role})` : `${p.country_code}-${p.party_id} ${p.company?.name || p.user.company?.name || p.user.username}`, 
                        value: p.id 
                    } 
                })
            })
        .catch(this.showError)
    },
    methods: {
      async loadChargeRequests() {
        this.loading = true
        await this.$api.get(`charge-requests?date_from=${new Date(this.selectedDateFrom).toISOString()}`)
            .then((resp) => this.chargeRequests = resp.data.map(function(obj, index) { return Object.assign({}, obj, { id: index })}) )
            .catch(this.showError)
            .then(() => this.loading = false)
      },
      async onFilter(){
        if(this.loading) return
        this.tableSearch = this.selectedSearch
        this.urlPush('search', this.selectedSearch)
        this.urlPush('provider', this.selectedProvider)
        this.urlPush('dateFrom', this.selectedDateFrom)

        await this.loadChargeRequests()

        if(this.selectedProvider){
            this.chargeRequests = this.chargeRequests.filter(cr => cr.user.provider_id == this.selectedProvider)
        }
      }
    },
};
</script>

<style scoped>
    .json-text-orange {
        white-space: nowrap;
        color: #e08331;
        font-size: 14px;
        font-family: Consolas, Menlo, Courier, monospace;
    }
    >>> .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
        box-shadow: none;
    }
</style>