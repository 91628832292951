import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c('AppBar',{attrs:{"title":"Connections","icon":"mdi-vector-polyline"}}),_c(VRow,{staticClass:"filer-row"},[_c(VCol,{attrs:{"cols":"12","md":"2"}},[_c(VTextField,{staticClass:"search-field",attrs:{"placeholder":"Search","color":"accent","autofocus":"","outlined":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onFilter.apply(null, arguments)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c(VBtn,{attrs:{"color":"accent","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.onFilter.apply(null, arguments)}}},[_c(VIcon,[_vm._v("mdi-magnify")])],1)]},proxy:true}]),model:{value:(_vm.selectedSearch),callback:function ($$v) {_vm.selectedSearch=$$v},expression:"selectedSearch"}})],1),_c(VBtn,{attrs:{"loading":_vm.tableLoading,"color":"accent","text":""},on:{"click":_vm.onFilter}},[_vm._v(" SEARCH ")]),_c(VSpacer),_c(VBtn,{staticClass:"mr-2",attrs:{"to":"/connection/create","color":"accent","outlined":""}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v("Create ")],1)],1),_c(VDataTable,{staticClass:"accent-table rounded-table",attrs:{"headers":[
            { value: 'linkto', sortable: false, cellClass: 'td-linkto' },
            { text: 'Id.', value: 'id' },
            { text: 'Name', value: 'name' },
            { text: 'Type', value: 'type', align: 'center' },
            { text: 'Status', value: 'status', align: 'center' },
            { text: 'URL', value: 'ocpi_url_versions' },
            { text: 'OCPI Ver.', value: 'ocpi_version' },
            { text: 'Updated', value: 'updated_at' }
        ],"items":_vm.connections,"loading":_vm.tableLoading,"search":_vm.tableSearch,"sort-by":['id'],"sort-desc":[false],"disable-pagination":"","hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.linkto",fn:function(ref){
        var item = ref.item;
return [_c(VBtn,{attrs:{"plain":"","small":"","to":("/connection/" + (item.id))}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-arrow-top-right")])],1)]}},{key:"item.type",fn:function(ref){
        var item = ref.item;
return [(item.type)?_c(VChip,{attrs:{"color":"accent","outlined":"","small":""}},[_vm._v(" "+_vm._s(item.type == 'provider' ? 'DIRECT' : item.type.toUpperCase())+" ")]):_vm._e()]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [(item.status)?_c('StatusChip',{attrs:{"status":item.status}}):_vm._e()]}},{key:"item.ocpi_version",fn:function(ref){
        var item = ref.item;
return [(item.ocpi_version)?_c(VChip,{attrs:{"color":"accent","outlined":"","small":""}},[_vm._v(" "+_vm._s(item.ocpi_version)+" ")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }