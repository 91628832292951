<template>
    <v-container fluid>

        <AppBar title="Export Locations to Core" icon="mdi-database-export" />

        <div class="d-flex my-4">
            <v-icon color="accent">mdi-database-export-outline</v-icon>
            <h3 class="ml-2">Select Locations to export to Core</h3>
        </div>

        <v-textarea
            class="mb-4 text-overline" style="font-size: 1rem!important"
            v-model="sqlquery"
            auto-grow
            outlined
            hide-details
            background-color="grey lighten-4"
        >
            <template v-slot:prepend-inner>
                SELECT id FROM (
            </template>
            <template v-slot:append>
                ) AS a
            </template>
        </v-textarea>

        <v-row v-if="!loading" class="ma-0" align="center">
            <v-btn @click="exportLocations" color="accent">Export</v-btn>
            <v-chip v-if="results" class="ml-2" outlined color="accent">{{ exported }} Locations exported to Core</v-chip>
        </v-row>

        <v-row v-else class="ma-0" align="center">
            <v-btn  @click="forceStop" color="accent">Force Stop</v-btn>
            <v-chip class="ml-2" outlined color="accent">
                <v-progress-circular
                    class="mr-2"
                    :size="16"
                    width="2"
                    indeterminate
                    color="accent"
                />
                <template v-if="stopping">
                    Stopping
                </template>
                <template v-else>
                    Exported {{ exported }} of {{ total ? total : '?' }}
                </template>
            </v-chip>
        </v-row>

        <v-card v-if="results" 
            class="mt-4"
            outlined
        >
            <v-data-table
                :items="results"              
                :headers="[
                    { text: 'Location id', value: 'loc_id' },
                    { text: 'Chargepoint id', value: 'chargepoint_id' },
                    { text: 'Success?', value: 'success' },
                    { text: 'Message', value: 'message' },
                ]"
                dense
                group-by="location_id"
                sort-by="chargepoint_id"
                :loading="loading"
            >
                <template slot="progress">
                    <v-progress-linear v-model="progress" color="accent"></v-progress-linear>
                </template>
                <template v-slot:group.header="{ group, items, headers, toggle, isOpen }">
                    <!-- Location id -->
                    <td>
                        <v-row class="ma-0 pa-0" align="center">
                            <v-btn @click="toggle" icon :ref="group">
                                <v-icon v-if="isOpen">mdi-minus</v-icon>
                                <v-icon v-else>mdi-plus</v-icon>
                            </v-btn>
    
                            <div style="white-space: nowrap;">
                                <v-btn class="px-1" style="min-width: 16px;" plain small :to="`/location/${group}`">
                                    <v-icon small>mdi-arrow-top-right</v-icon>
                                </v-btn>
                                {{ group }}
                            </div>
                        </v-row>
                    </td>
                    <!-- Chargepoint id -->
                    <td>
                        {{ items.filter(i => !!i.chargepoint_id).map(i => i.chargepoint_id) }}
                    </td>
                    <!-- Success? -->
                    <td>
                        <v-icon :color="items.every(i => i.success) ? 'success' : 'error'">
                            {{ items.every(i => i.success) ? 'mdi-check-circle' : 'mdi-close-circle' }}
                        </v-icon>
                    </td>
                    <td :colspan="headers.length - 3"></td>
                </template>
                <template v-slot:item.loc_id="{ item }">
                    <div style="white-space: nowrap;">
                        <v-btn class="px-1" style="min-width: 16px;" plain small :to="`/location/${item.location_id}`">
                            <v-icon small>mdi-arrow-top-right</v-icon>
                        </v-btn>
                        {{ item.location_id }}
                    </div>
                </template>
                <template v-slot:item.chargepoint_id="{ item }">
                    <div v-if="item.chargepoint_id" style="white-space: nowrap;">
                        <v-btn class="px-1" style="min-width: 16px;" plain small :to="`/chargepoint/${item.chargepoint_id}`">
                            <v-icon small>mdi-arrow-top-right</v-icon>
                        </v-btn>
                        {{ item.chargepoint_id }}
                    </div>                       
                </template>
                <template v-slot:item.success="{ item }">
                    <v-icon :color="item.success ? 'success' : 'error'">
                        {{ item.success ? 'mdi-check-circle' : 'mdi-close-circle' }}
                    </v-icon>
                </template>
            </v-data-table>
        </v-card>

    </v-container>
</template>

<script>
import AppBar from "@/components/AppBar"
import Bws from "@/plugins/BackofficeWebsocket.js"

export default {
    components: {
        AppBar
    },
    data() {
        return {
            sqlquery: `SELECT DISTINCT lo.id FROM locations lo
LEFT JOIN charge_points cp ON cp.location_id = lo.id
LEFT JOIN evses e ON e.chargepoint_id = cp.id
LEFT JOIN connectors c ON c.evse_id = e.id
WHERE lo.provider_id = 52 
    AND c.max_electric_power >= 150000 
    AND lo.deleted_at is null 
    AND e.deleted_at is null 
    AND lo.publish = true
LIMIT 0`,
            loading: false,
            stopping: false,
            results: null,
            progress: 0,
            total: 0,
            exported: 0
        }
    },
    async mounted(){
        this.wsChannel = Bws.getSubscription('export-locations-to-core:results')

        this.wsChannel.on("message", (data) => {
            this.results = data.results
            this.progress= data.progress
            this.total = data.total
            this.exported = data.exported

            if (data.finished) {
                this.loading = false
                this.stopping = false
            }
        })

        this.wsChannel.on("error", (error) => {
            this.showError(error)
            this.loading = false
            this.stopping = false
        })
    },
    methods: {
        async exportLocations(){
            this.loading = true
            
            // Removed previous answer to update table
            this.results = []
            this.progress = 0
            this.total = 0
            this.exported = 0

            try {
                this.wsChannel.emit('export', { sqlquery: this.sqlquery })
            } catch(e) {
                this.loading = false
                this.showError(e)
            }
        },
        async forceStop(){
            this.stopping = true
            this.wsChannel.emit('stop')
        }
    }
}
</script>

<style scoped>
    >>> .v-data-table__progress .column {
        padding: 0;
    }
    >>> .v-text-field__slot textarea {
        margin: 0!important;
        padding-top: 32px;
        padding-bottom: 32px;
        padding-left: 18px;
    }
    >>> .v-input__prepend-inner {
        position: absolute;
        padding: 0;
        margin: 0;
        top: 12px;
        left: 12px;
    }
    >>> .v-input__append-inner {
        position: absolute;
        bottom: 0;
        left: 0;
        padding-bottom: 12px;
        padding-left: 12px!important;
    }
</style>
