import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,{staticClass:"filer-row"},[_c(VSpacer),_c(VCol,{attrs:{"cols":"12","md":"2"}},[_c(VMenu,{ref:"menu",staticClass:"filter-field",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto","hide-details":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({staticClass:"filter-field",attrs:{"label":"Date from","prepend-inner-icon":"mdi-calendar","color":"accent","readonly":"","clearable":"","outlined":"","hide-details":""},model:{value:(_vm.selectedDateFrom),callback:function ($$v) {_vm.selectedDateFrom=$$v},expression:"selectedDateFrom"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.showDateFromPicker),callback:function ($$v) {_vm.showDateFromPicker=$$v},expression:"showDateFromPicker"}},[_c(VDatePicker,{attrs:{"no-title":""},on:{"input":function($event){_vm.showDateFromPicker = false}},model:{value:(_vm.selectedDateFrom),callback:function ($$v) {_vm.selectedDateFrom=$$v},expression:"selectedDateFrom"}})],1)],1),_c(VCol,{attrs:{"cols":"12","md":"2"}},[_c(VMenu,{ref:"menu",staticClass:"filter-field",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto","hide-details":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({staticClass:"filter-field",attrs:{"label":"Date to","prepend-inner-icon":"mdi-calendar","color":"accent","readonly":"","clearable":"","outlined":"","hide-details":""},model:{value:(_vm.selectedDateTo),callback:function ($$v) {_vm.selectedDateTo=$$v},expression:"selectedDateTo"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.showDateToPicker),callback:function ($$v) {_vm.showDateToPicker=$$v},expression:"showDateToPicker"}},[_c(VDatePicker,{attrs:{"no-title":""},on:{"input":function($event){_vm.showDateToPicker = false}},model:{value:(_vm.selectedDateTo),callback:function ($$v) {_vm.selectedDateTo=$$v},expression:"selectedDateTo"}})],1)],1),_c(VBtn,{attrs:{"loading":_vm.tableLoading,"color":"accent","text":""},on:{"click":_vm.onFilter}},[_vm._v(" SEARCH ")])],1),_c(VDivider),_c(VDataTable,{staticClass:"accent-table rounded-table",attrs:{"headers":[
          { value: 'action', sortable:false, cellClass: 'td-linkto' },
          { text: 'Id.', value: 'id' },
          { text: 'Start Date', value: 'start_date_time' },
          { text: 'End Date', value: 'end_date_time' },
          { text: 'CPO / EMSP', value: 'sender_receiver' },
          { text: 'UID', value: 'ocpi_id' },
          { text: 'Location', value: 'location', width:'200px' },
          { text: 'Charge point', value: 'chargepoint', width:'200px' },
          { text: 'Evse', value: 'evse.uid' },
          { text: 'Energy (kWh)', value: 'kwh' },
          { text: 'Id. DTM', value: 'dtm_session_id' },
          { text: 'Last updated', value: 'last_updated' } ],"items":_vm.sessions,"disable-sort":"","items-per-page":_vm.tableOptions.itemsPerPage,"server-items-length":_vm.pagination.serverItemsLength,"footer-props":{ 'items-per-page-options': _vm.pagination.itemsPerPageOptions }},on:{"update:options":this.updateTableOptions},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
      var item = ref.item;
return [_c(VBtn,{attrs:{"plain":"","small":"","to":("/session/" + (item.id))}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-arrow-top-right")])],1)]}},{key:"item.sender_receiver",fn:function(ref){
      var item = ref.item;
return [(item.sender_id === 1)?_c(VChip,{staticClass:"text-capitalize",attrs:{"color":"secondary","small":""}},[_vm._v(_vm._s(item.sender_name))]):_c('strong',[_c('ProviderName',{attrs:{"provider":item.senderProvider,"role":"cpo"}})],1),_c(VIcon,{attrs:{"color":"secondary"}},[_vm._v("mdi-arrow-right")]),(item.receiver_id === 1)?_c(VChip,{staticClass:"text-capitalize",attrs:{"color":"accent","small":""}},[_vm._v(_vm._s(item.receiver_name))]):_c('strong',[_c('ProviderName',{attrs:{"provider":item.receiverProvider,"role":"emsp"}})],1)]}},{key:"item.location",fn:function(ref){
      var item = ref.item;
return [_c('LocationName',{attrs:{"location":{ id: item.location_id, name: item.location_name },"type":"table"}})]}},{key:"item.chargepoint",fn:function(ref){
      var item = ref.item;
return [_c('ChargepointName',{attrs:{"chargepoint":{ id: item.chargepoint_id, name: item.chargepoint_name },"type":"table"}})]}},{key:"item.start_date_time",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFullFormat")(item.start_date_time))+" ")]}},{key:"item.end_date_time",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFullFormat")(item.end_date_time))+" ")]}},{key:"item.last_updated",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFullFormat")(item.last_updated))+" ")]}}])}),(_vm.hasRole('CPO'))?_c('ImportSessionsFromProvider',{staticClass:"mt-2",attrs:{"provider-id":_vm.providerId},on:{"data-imported":function($event){return _vm.loadSessions()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }