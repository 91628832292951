<template>
    <v-dialog v-model="show" persistent max-width="1180px">
        <v-card v-if="provider">
            <v-card-title class="accent white--text">
                <span class="headline">Add group to Provider {{ providerName }}</span>
            </v-card-title>
            <v-card-text class="pa-4" v-if="provider.id">

                <v-select
                    v-model="selectedGroup"
                    return-object
                    :items="groups"
                    item-text="name"
                    color="accent"
                    label="Elegir el grupo"
                    outlined
                ></v-select>

                <div v-if="selectedGroup" class="title mb-2">
                    Add group <v-chip outlined color="accent">{{selectedGroup.name}}</v-chip> to <v-chip outlined color="accent">{{providerName}}</v-chip> ?
                </div>

            </v-card-text>
            <v-card-actions>
                <v-btn v-if="selectedGroup" @click="addGroup" depressed color="accent" :loading="actionLoading">Continue</v-btn>
                <v-spacer />
                <v-btn @click="show = false" text>Cancel</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { EventBus } from "@/event-bus"

export default {
    data() {
        return {
            show: false,
            provider: null,
            groups: null,
            selectedGroup: null,
            actionLoading: false
        }
    },
    watch: {
        show( visible ) {
            if ( !visible ) {
                this.provider = null
                this.selectedGroup = null
                EventBus.$emit("dialog-user-add-location-group:close")
            }
        }
    },
    computed: {
        providerName(){
            if( !this.provider ){ return "?" }
            let name = `${this.provider.country_code} - ${this.provider.party_id}`
            if( this.provider.company ){ name += ` ${this.provider.company.name}` }
            else if( this.provider.user ){ name += ` ${this.provider.user.company?.name || this.provider.user.username}`}
            return name
        }
    },
    created() {
        var vm = this;
        EventBus.$on("dialog-provider-add-location-group:open", async function( provider ) {

            if( !provider ){
                vm.showError("Data error, no Provider")
                return false
            }

            vm.provider = provider
            vm.groups = await this.$api.get('location-groups')
                .then(resp => resp.data)
                .catch(this.showError)

            vm.show = true

        })
    },
    methods: {
        async addGroup(){
            this.actionLoading = true
            await this.$api.put(`provider/${this.provider.id}/add-group/${this.selectedGroup.id}`)
                .then(res => this.showSuccess("Group added"))
                .catch(this.showError)
            this.actionLoading = false
            this.show = false
        }
    }
}
</script>