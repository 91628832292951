<template>
  <div>
    <v-row class="filer-row">
      <v-spacer></v-spacer>

      <v-col cols="12" md="2">
          <v-text-field
            class="search-field"
            v-model="selectedSearch"
            placeholder="Buscar"
            color="accent"
            outlined
            hide-details
            @keyup.enter="onFilter"
          >
            <template v-slot:append>
              <v-btn
                  color="accent" 
                  text
                  @click.stop="onFilter"
              >
                  <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
          </v-text-field>
      </v-col>

      <v-btn 
          :loading="loading"
          @click="onFilter"
          color="accent"
          text
      >
          SEARCH
      </v-btn>
    </v-row>

    <v-data-table
      :headers="[
        { value: 'action', sortable:false, cellClass: 'td-linkto' },
        { text: 'OCPI Id.', value: 'ocpi_id' },
        { text: 'Name', value: 'name' },
        { text: 'City', value: 'city' },
        { text: 'EVSEs', value: 'evses.length' },
        { text: 'Core Id', value: 'backend_id' },
        { text: 'Updated', value: 'updated_at' },
        { text: 'Deleted', value: 'deleted_at' }
      ]"
      class="accent-table rounded-table"
      :items="locations"
      :items-per-page="tableOptions.itemsPerPage"
      :server-items-length="tablePagination.serverItemsLength"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': tablePagination.itemsPerPageOptions,
      }"
      @update:options="this.updateTableOptions"
      disable-sort
      dense
    >
        <template v-slot:item.action="{ item }">
            <v-btn plain
                small
                :to="`/location/${item.id}`" 
                >
                <v-icon small>mdi-arrow-top-right</v-icon>
            </v-btn>
        </template>
        <template v-slot:item.name="{ item }">
                <LocationName
                    :location="item"
                    extra="no-arrow"
                    type="table"
                />
            </template>
    </v-data-table>

    <ImportLocationsFromProvider :provider-id="providerId" @data-imported="getLocations()" class="mt-2"></ImportLocationsFromProvider>
  </div>
</template>
<script>
import _ from "lodash";
import ImportLocationsFromProvider from "@/components/provider/ImportLocationsFromProvider";
import LocationName from "@/components/location/LocationName";

export default {
  name: "locations-from-provider",
  props: ["providerId"],
  components: {
    ImportLocationsFromProvider,
    LocationName,
  },
  data() {
    return {
      loading: false,
      provider: null,
      locations: [],
      selectedSearch: "",
      loader: null,
      tablePagination: {
        itemsPerPageOptions: [25, 50, 100, -1],
        serverItemsLength: 0,
      },
      tableOptions: {
        itemsPerPage: 25,
        page: 1,
      },
    };
  },
  mounted() {
    this.getLocations()
  },
  methods: {
    getLocations() {
      if (!this.providerId) { return }

      this.loading = true

      let queryParams = ""
      queryParams += `offset=${this.tableOptions.itemsPerPage * this.tableOptions.page - this.tableOptions.itemsPerPage}`
      queryParams += "&orderBy[name]=asc"
      if (this.tableOptions.itemsPerPage > 0) { queryParams += `&limit=${this.tableOptions.itemsPerPage}` }
      if (this.selectedSearch && this.selectedSearch !== "") { queryParams += `&orfilter[ocpi_id,name,city,updated_at]=${this.selectedSearch}` }

      this.$api
        .get(`provider/${this.providerId}/locations?${queryParams}`)
        .then((resp) => {
          this.tablePagination.serverItemsLength = Number(resp.headers["x-total-count"])
          if (Array.isArray(resp.data)) {
            this.locations = resp.data
          }
        })
        .catch(this.showError)
        .then((_) => {
          this.loading = false
        });
    },
    onFilter(){
        if(this.loading) return
        this.getLocations()
    },
    updateTableOptions(args) {
      this.tableOptions = args
      this.getLocations()
    }
  },
};
</script>

<style>
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>